import { IImage } from '../../types/media';

import PropertyCarousel from '../PropertyCarousel/PropertyCarousel';

interface IPropertyListCarouselProps {
  items: IImage[];
}

const PropertyListCarousel: React.FC<IPropertyListCarouselProps> = ({
  items,
}) => {
  const sliderSettings = {
    customPaging: (index: number) =>
      items ? (
        <div className="h-[70px] w-[100px]">
          <img
            src={items[index]?.url}
            width={50}
            height={50}
            alt={index.toString()}
            className="h-[70px] w-[100px] object-cover rounded-[4px]"
          />
        </div>
      ) : (
        <div />
      ),
    dots: true,
    dotsClass: '!flex gap-[10px] overflow-auto cursor-pointer',
  };

  return (
    <PropertyCarousel
      items={items}
      settings={sliderSettings}
      wrapperClass="aspect-[1.34] mb-2.5"
      imageClass="rounded-[10px]"
    />
  );
};

export default PropertyListCarousel;
