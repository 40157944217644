import { useMemo } from 'react';

import { IPropertySearch } from '../../types/dto/property';

import PropertyTile from './PropertyTile';
import PropertyListItem from './PropertyListItem';

interface IPropertyListProps {
  items: IPropertySearch[];
  isTiles?: boolean;
}

const PropertyList: React.FC<IPropertyListProps> = ({
  items = [],
  isTiles = false,
}) => {
  const itemsTilesMap = useMemo(
    () => items.map((item) => <PropertyTile key={item.id} item={item} />),
    [items],
  );
  const itemsListMap = useMemo(
    () => items.map((item) => <PropertyListItem key={item.id} item={item} />),
    [items],
  );

  return isTiles ? (
    <div className="grid grid-cols-5 gap-x-[25px] gap-y-[35px]">
      {itemsTilesMap}
    </div>
  ) : (
    <div className="grid gap-[30px]">{itemsListMap}</div>
  );
};

export default PropertyList;
