import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const MapIcon: FC<ISvgProps> = ({
  height = 26,
  width = 25,
  color = '#CCCCCC',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4167_10912)">
        <path
          d="M0 1.5C0 0.947715 0.447715 0.5 1 0.5H10.25C10.8023 0.5 11.25 0.947715 11.25 1.5V10.75C11.25 11.3023 10.8023 11.75 10.25 11.75H1C0.447715 11.75 0 11.3023 0 10.75V1.5ZM3.5 3C2.94772 3 2.5 3.44772 2.5 4V8.25C2.5 8.80228 2.94772 9.25 3.5 9.25H7.75C8.30228 9.25 8.75 8.80229 8.75 8.25V4C8.75 3.44772 8.30229 3 7.75 3H3.5ZM0 15.25C0 14.6977 0.447715 14.25 1 14.25H10.25C10.8023 14.25 11.25 14.6977 11.25 15.25V24.5C11.25 25.0523 10.8023 25.5 10.25 25.5H1C0.447715 25.5 0 25.0523 0 24.5V15.25ZM3.5 16.75C2.94772 16.75 2.5 17.1977 2.5 17.75V22C2.5 22.5523 2.94772 23 3.5 23H7.75C8.30228 23 8.75 22.5523 8.75 22V17.75C8.75 17.1977 8.30229 16.75 7.75 16.75H3.5ZM13.75 1.5C13.75 0.947715 14.1977 0.5 14.75 0.5H24C24.5523 0.5 25 0.947715 25 1.5V10.75C25 11.3023 24.5523 11.75 24 11.75H14.75C14.1977 11.75 13.75 11.3023 13.75 10.75V1.5ZM17.25 3C16.6977 3 16.25 3.44772 16.25 4V8.25C16.25 8.80228 16.6977 9.25 17.25 9.25H21.5C22.0523 9.25 22.5 8.80229 22.5 8.25V4C22.5 3.44772 22.0523 3 21.5 3H17.25ZM13.75 15.25C13.75 14.6977 14.1977 14.25 14.75 14.25H24C24.5523 14.25 25 14.6977 25 15.25V24.5C25 25.0523 24.5523 25.5 24 25.5H14.75C14.1977 25.5 13.75 25.0523 13.75 24.5V15.25ZM17.25 16.75C16.6977 16.75 16.25 17.1977 16.25 17.75V22C16.25 22.5523 16.6977 23 17.25 23H21.5C22.0523 23 22.5 22.5523 22.5 22V17.75C22.5 17.1977 22.0523 16.75 21.5 16.75H17.25Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_4167_10912">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MapIcon;
