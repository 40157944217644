import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const ChevronIcon: FC<ISvgProps> = ({
  height = 15,
  width = 7,
  color = 'black',
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 7 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.38934 14.5739C6.46924 14.5744 6.54845 14.559 6.62242 14.5288C6.6964 14.4986 6.76368 14.4541 6.82041 14.3978C6.87731 14.3414 6.92248 14.2742 6.95331 14.2002C6.98413 14.1263 7 14.0469 7 13.9668C7 13.8866 6.98413 13.8072 6.95331 13.7333C6.92248 13.6593 6.87731 13.5921 6.82041 13.5357L1.86005 8.57533C1.51896 8.23381 1.32737 7.77087 1.32737 7.28819C1.32737 6.80551 1.51896 6.34257 1.86005 6.00105L6.82041 1.0407C6.93474 0.92637 6.99896 0.771309 6.99896 0.609626C6.99896 0.447943 6.93474 0.292883 6.82041 0.178555C6.70608 0.0642283 6.55102 0 6.38934 0C6.22765 0 6.07259 0.0642283 5.95827 0.178555L0.997908 5.13891C0.715203 5.4209 0.490907 5.75589 0.337868 6.1247C0.184829 6.49351 0.106053 6.88889 0.106053 7.28819C0.106053 7.68749 0.184829 8.08287 0.337868 8.45168C0.490907 8.82049 0.715203 9.15548 0.997908 9.43747L5.95827 14.3978C6.015 14.4541 6.08228 14.4986 6.15625 14.5288C6.23022 14.559 6.30943 14.5744 6.38934 14.5739Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronIcon;
