import { FC, ReactNode } from 'react';

import { capitalizeFirstLetter } from '../../common/utils/helpers';

interface IPropertyWrapperProps {
  children?: ReactNode;
  label?: string;
  icon?: ReactNode;
}

const PropertyFieldWrapper: FC<IPropertyWrapperProps> = ({
  children,
  label,
  icon,
}) => {
  return (
    <div>
      <div className="flex items-center gap-[5px] mb-[18px]">
        {icon}
        <div className="text-[#333] text-[20px] leading-7 font-semibold">
          {capitalizeFirstLetter(label)}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default PropertyFieldWrapper;
