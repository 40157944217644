import React, { useCallback, useRef } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

import { useGoogleMaps } from '../../../api/google/GoogleMapsProvider';

import { mapStyle } from '../../../common/constants/map';

const mapContainerStyle = { width: '100%', height: '100%' };

const circleIcon = `
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 30 30">
              <circle cx="15" cy="15" r="15" fill="#006CFB" />
            </svg>
          `;

const defaultZoom = 17;

interface IPropertyMapProps {
  lat: number;
  lng: number;
}

const PropertyMap: React.FC<IPropertyMapProps> = ({ lat, lng }) => {
  const center = { lat, lng };

  const mapRef = useRef<google.maps.Map | null>(null);

  const googleMapsContext = useGoogleMaps();

  if (!googleMapsContext) {
    return <div>Loading Map...</div>;
  }

  const { isApiLoaded, loadError } = googleMapsContext;

  const handleLoad = useCallback((map: google.maps.Map) => {
    if (map) {
      mapRef.current = map;
      map.setOptions({
        disableDefaultUI: true,
        styles: mapStyle,
      });
    }
  }, []);

  const zoomIn = () => {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom();
      if (currentZoom !== undefined) {
        mapRef.current.setZoom(currentZoom + 1);
      }
    }
  };

  const zoomOut = () => {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom();
      if (currentZoom !== undefined) {
        mapRef.current.setZoom(currentZoom - 1);
      }
    }
  };

  if (!lat || !lng) return null;

  if (loadError) return <div>Loading Map Failed!</div>;

  return isApiLoaded ? (
    <div className="relative h-full">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={defaultZoom}
        onLoad={handleLoad}
        options={{
          disableDefaultUI: true,
          clickableIcons: false,
        }}
      >
        <MarkerF
          position={{ lat, lng }}
          icon={{
            url:
              'data:image/svg+xml;charset=UTF-8,' +
              encodeURIComponent(circleIcon),
          }}
        />
      </GoogleMap>
      <div className="absolute bottom-[16px] right-[16px] flex flex-col items-center w-[30px] bg-[#006CFB] rounded-[8px]">
        <button
          className="flex-center w-[30px] h-[37px] text-[#ffffff]"
          onClick={zoomIn}
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="15"
              y1="5"
              x2="15"
              y2="25"
              stroke="#fff"
              strokeWidth="2"
            />
            <line
              x1="5"
              y1="15"
              x2="25"
              y2="15"
              stroke="#fff"
              strokeWidth="2"
            />
          </svg>
        </button>
        <button
          className="flex-center w-[30px] h-[37px] text-[#ffffff]"
          onClick={zoomOut}
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="5"
              y1="15"
              x2="25"
              y2="15"
              stroke="#fff"
              strokeWidth="2"
            />
          </svg>
        </button>
      </div>
    </div>
  ) : (
    <div>Loading Map...</div>
  );
};

export default PropertyMap;
