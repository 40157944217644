import { Outlet } from 'react-router-dom';

import Header from '../../common/components/Header/Header';
import Footer from '../../common/components/Footer/Footer';

// import facebookIcon from './../assets/icons/facebook.svg';
// import instagramIcon from './../assets/icons/instagram.svg';
// import twitterIcon from './../assets/icons/twitter.svg';
// import worldIcon from './../assets/icons/world.svg';
// import youtubeIcon from './../assets/icons/youtube.svg';

const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
