import { useMemo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getNamesByPath, getSubTree } from '../../utils/helpers';

import usePropertySearchStore from '../../stores/usePropertySearchStore';

import usePropertyCategories, {
  IConvertedCategory,
} from '../../hooks/usePropertyCategories';

import Dropdown from '../Dropdown/Dropdown';

import CheckIcon from '../../assets/icons/CheckIcon';

const PropertyTypeSelector = () => {
  const { type } = useParams();

  const { t } = useTranslation();

  const { propertyTypes, setPropertyTypes } = usePropertySearchStore();

  const { categories } = usePropertyCategories();

  const searchValueString = useMemo(() => {
    const pathNames = getNamesByPath(categories, propertyTypes.path);
    if (!pathNames?.length) return `${t('loading')}...`;

    const normalizedPath = pathNames.join(', ');

    if (propertyTypes.entities?.length === 1) {
      const entityId = propertyTypes.entities[0].id;
      const entities =
        getSubTree(categories, propertyTypes.path)?.entities || [];
      const entityName =
        entities.find((ent) => ent.id === entityId)?.title || '';

      return `${normalizedPath}, ${entityName}`;
    }

    return normalizedPath;
  }, [categories, propertyTypes.path, propertyTypes.entities, t]);

  const handleRadioChange = useCallback(
    (level: number, selectedValue: string, path = [...propertyTypes.path]) => {
      path[level] = selectedValue;
      path.splice(level + 1);

      const currentSubTree = getSubTree(categories, path);

      const subTreeChildren = currentSubTree?.children;
      if (subTreeChildren?.length) {
        const firstChildKey = subTreeChildren[0].name;

        if (firstChildKey) {
          path.push(firstChildKey);
          handleRadioChange(level + 1, firstChildKey, path);
        }
      }

      const newSubTree = getSubTree(categories, path);

      const finalEntities = newSubTree?.entities || [];

      const newValue = {
        path: path,
        entities: finalEntities.length > 0 ? [finalEntities[0]] : [],
      };

      setPropertyTypes(newValue);
    },
    [propertyTypes, categories, setPropertyTypes],
  );

  const handleCheckboxChange = useCallback(
    (entity: any) => {
      if (
        propertyTypes.entities.find((ent) => ent.id === entity.id) &&
        propertyTypes.entities.length === 1
      )
        return;

      const updatedEntities = [entity];
      const updatedValue = { ...propertyTypes, entities: updatedEntities };

      setPropertyTypes(updatedValue);
    },
    [propertyTypes, setPropertyTypes],
  );

  const renderTree = useCallback(
    (treeArr?: IConvertedCategory[], level = 0) => {
      if (!treeArr) return null;
      const currentSelection = propertyTypes.path[level] || null;
      const currentObject = treeArr.find(
        (item) => item.name === currentSelection,
      );

      return (
        <div className="flex flex-col gap-[15px]">
          <div className="flex items-center gap-[10px]">
            {treeArr.map((obj) => (
              <label
                className={`flex-center cursor-pointer rounded-[8px] h-[26px] px-[18px] text-[12px] fade-in animation-fast ${currentSelection === obj.name ? 'bg-[#2C66F6] text-white' : 'bg-[#EAEFFE] text-[#2C66F6]'}`}
                key={obj.title}
              >
                <input
                  hidden
                  type="radio"
                  name={`level-${level}`}
                  checked={currentSelection === obj.name}
                  onChange={() => handleRadioChange(level, obj.name)}
                />
                {obj.title}
              </label>
            ))}
          </div>

          {/*<div className="text-[12px] text-[#666666]">*/}
          {/*  {currentObject?.description}*/}
          {/*</div>*/}

          {!!currentObject && renderTree(currentObject.children, level + 1)}

          {!!currentObject?.entities?.length && (
            <div>
              {currentObject.entities.map((entity) => (
                <label
                  key={entity.id}
                  className="flex items-center justify-between w-full h-[34px] border-b border-[#F7F7F7] fade-in animation-fast"
                >
                  <div className="text-[12px] text-[#333333]">
                    {entity.title}
                  </div>
                  <div className="relative flex-center w-4 h-4">
                    <input
                      type="checkbox"
                      className="appearance-none w-4 h-4 border border-[#006CFB] rounded-[2px] bg-transparent shrink-0 cursor-pointer checked:rounded-[4px] checked:bg-[#006CFB]"
                      checked={
                        !!propertyTypes.entities.find(
                          (ent) => ent.id === entity.id,
                        )
                      }
                      onChange={() => handleCheckboxChange(entity)}
                    />
                    <CheckIcon
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                      height={10}
                      width={10}
                      color="white"
                      strokeWidth={2.5}
                    />
                  </div>
                </label>
              ))}
            </div>
          )}
        </div>
      );
    },
    [propertyTypes, handleRadioChange, handleCheckboxChange],
  );

  const renderedTree = useMemo(
    () => renderTree(categories),
    [categories, renderTree],
  );

  useEffect(() => {
    if (!propertyTypes.path.length && categories.length) {
      const autoSelectFirstFromTree = (
        tree: IConvertedCategory | null,
        param: keyof IConvertedCategory,
      ) => {
        const path: string[] = [];
        let currentTree = tree;
        while (currentTree) {
          const firstKey = currentTree[param];
          path.push(firstKey as string);
          currentTree = currentTree.children ? currentTree.children[0] : null;
        }
        return path;
      };
      const firstTree = categories[type === 'buy' ? 1 : 0];

      const path =
        !type || type === 'rent'
          ? ['rent', 'longTerm', 'residental']
          : autoSelectFirstFromTree(firstTree, 'name');

      const currentSubTree = getSubTree(categories, path);

      const finalEntities = currentSubTree?.entities || [];

      const updatedValue = {
        path,
        entities: finalEntities.length > 0 ? [finalEntities[0]] : [],
      };

      if (JSON.stringify(propertyTypes.path) !== JSON.stringify(path)) {
        setPropertyTypes(updatedValue);
      }
    }
  }, [categories, type, propertyTypes, setPropertyTypes]);

  return (
    <div>
      <Dropdown
        className="w-[223px] h-[32px] after:absolute after:top-2 after:right-0 after:block after:content-[''] after:w-[1px] after:h-[16px] after:bg-[#D9D9D9]"
        buttonClass="text-[12px] text-[#8C8C8C] font-semibold"
        menuClass="-ml-[18px]"
        title={searchValueString}
      >
        <div className="flex flex-col gap-[15px] min-w-[278px] p-[15px]">
          {/*<div className="text-[12px] text-[#666666]">*/}
          {/*  {t('transaction-type')}*/}
          {/*</div>*/}
          {renderedTree}
        </div>
      </Dropdown>
    </div>
  );
};

export default PropertyTypeSelector;
