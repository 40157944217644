import React from 'react';
import { useTranslation } from 'react-i18next';

import FilterControls from '../FilterControls';

import PriceHardcodedField from '../fields/PriceHardcodedField';
import RadioField from '../fields/RadioField';
import TagSelectField from '../fields/TagSelectField';
import CounterField from '../fields/CounterField';
import NumberField from '../fields/NumberField';

import CrossIcon from '../../../assets/icons/CrossIcon';

interface ITagOption {
  id: string;
  label: string;
}

interface IField {
  id: string;
  type: string;
  name: string;
  label: string;
  tags: ITagOption[];
  meta?: {
    isCounter?: boolean;
    unit?: string;
  };
  [key: string]: any;
}

interface IGroup {
  name: string;
  fields: IField[];
}

interface IFiltersModalProps {
  groups: IGroup[];
  onClose: () => void;
}

const FiltersModal: React.FC<IFiltersModalProps> = ({ groups, onClose }) => {
  const { t } = useTranslation();

  const parametersSchema = groups.find((g) => g.name === 'parameters');
  const featuresSchema = groups.find((g) => g.name === 'features');

  const FieldInput: React.FC<{ field: IField }> = ({ field }) => {
    switch (field.type) {
      case 'RADIO':
        return <RadioField field={field} />;
      case 'SELECT':
        return <RadioField field={field} isSelect />;
      case 'TAG_SELECT':
        return <TagSelectField field={field} />;
      case 'TAG_MULTI_SELECT':
        return <TagSelectField field={field} multi />;
      case 'NUMBER':
        return field.meta?.isCounter ? (
          <CounterField field={field} />
        ) : (
          <NumberField field={field} />
        );
      // case 'STRING':
      //   return <StringField field={field} />;
      // case 'DATE':
      //   return <DateField field={field} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`z-[1000] max-w-[60vw] max-h-[80vh] bg-white p-0 rounded-[8px] overflow-hidden border-0 shadow`}
    >
      <div className="flex flex-col justify-between p-[15px]">
        <div className="flex items-center justify-between mb-6">
          <div className="text-[25px] font-semibold leading-[1.36]">
            {t('filters')}
          </div>
          <div onClick={onClose} className="flex-center w-6 h-6 cursor-pointer">
            <CrossIcon width={14} height={14} strokeWidth={2} />
          </div>
        </div>
        <div className="overflow-auto max-h-[calc(80vh-160px)] pr-2">
          <div className="mb-6">
            <div className="font-bold mb-6">{t('parameters')}</div>
            <div className="flex flex-col gap-2.5">
              <PriceHardcodedField />
              {parametersSchema?.fields
                ?.sort((o1: any, o2: any) => o1.order - o2.order)
                .map((field) => <FieldInput key={field.id} field={field} />)}
            </div>
          </div>
          <div className="mb-2">
            <div className="font-bold mb-6">{t('features')}</div>
            <div className="flex flex-col gap-2.5">
              {featuresSchema?.fields?.map((field) => (
                <FieldInput key={field.id} field={field} />
              ))}
            </div>
          </div>
        </div>
        <div className="pt-6">
          <FilterControls onSubmit={onClose} />
        </div>
      </div>
    </div>
  );
};

export default FiltersModal;
