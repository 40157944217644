import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  addPriceDelimiters,
  formatPhoneNumber,
  generatePropertyDataString,
  getAddressString,
} from '../../utils/helpers';

import { IPropertySearch } from '../../types/dto/property';
import { IImage } from '../../types/media';

import useGlobalModalStore from '../../stores/useGlobalModalStore';

import DropdownSimple from '../Dropdown/DropdownSimple';

import ComplaintModal from '../../../features/property/components/modals/ComplaintModal';
import LinkModal from '../../../features/search/components/modals/LinkModal';

import PropertyListCarousel from './PropertyListCarousel';

import NoImageIcon from '../../assets/icons/NoImageIcon';
import LinkIcon from '../../assets/icons/LinkIcon';

import { ReactComponent as AchtungIcon } from '../../assets/icons/achtungIcon.svg';

interface PropertyListItemProps {
  item: IPropertySearch;
}

//todo: check translate after all done
const PropertyListItem: React.FC<PropertyListItemProps> = ({
  item: {
    id,
    mainImage,
    gallery = [],
    price,
    address,
    description = {},
    owner,
    contactPhone,
    additionalPhone,
    fields,
    category,
  },
}) => {
  const [showContacts, setShowContacts] = useState(false);

  const { lang } = useParams();
  const { t } = useTranslation();

  const { setChildren, setIsOpen } = useGlobalModalStore();

  const addressString = getAddressString(address);

  const allImages: IImage[] = useMemo(() => {
    const collateralImages = gallery;

    return mainImage ? [...[mainImage], ...collateralImages] : collateralImages;
  }, [gallery, mainImage]);

  const showMeterPrice =
    category?.name?.includes('sale') || category?.name?.includes('commercial');
  const meterPrice = fields?.totalArea
    ? Math.round(price / fields?.totalArea)
    : '';

  const showPerMonth = category?.name?.includes('longTerm'); // priceType === 'perMonth'

  const dataString = generatePropertyDataString(category, fields);

  const getFullLink = (id: string, lang?: string) => {
    return `${window.location.protocol}//${window.location.host}/${lang}/property/${id}`;
  };

  const fullLink = getFullLink(id, lang);

  const openComplaintModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setChildren(<ComplaintModal id={id} />);
    setIsOpen(true);
  };

  const handleShowContacts = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setShowContacts(true);
  };

  const preventPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Link
      className="flex gap-[25px] min-h-[400px] rounded-[8px] hover:bg-[#EAEFFE59] cursor-pointer fade-in"
      to={`/${lang}/property/${id}`}
    >
      <div className="w-[432px]">
        {allImages && allImages.length > 0 ? (
          <PropertyListCarousel items={allImages} />
        ) : (
          <div className="flex-center w-full h-full bg-[#F8F8F8] rounded-[10px]">
            <NoImageIcon />
          </div>
        )}
      </div>
      <div className="flex flex-1 flex-col justify-between pt-5">
        <div>
          <div className="flex items-center justify-between mb-[25px]">
            <div>
              <div className="text-[22px] text-[#006CFB] leading-[1] mb-1">
                {dataString}
              </div>
              <div className="text-[14px] text-[#8C8C8C] leading-[1]">
                {addressString}
              </div>
            </div>
            <div className="flex items-center gap-[15px]">
              <DropdownSimple
                title={
                  <div className="flex-center w-10 h-10 rounded-[8px] bg-[#ffffff] border border-[#EAEFFE]">
                    <LinkIcon />
                  </div>
                }
                menuClass="left-auto right-0"
              >
                <LinkModal link={fullLink} />
              </DropdownSimple>
              <button
                onClick={openComplaintModal}
                className="flex-center w-10 h-10 rounded-[8px] bg-[#ffffff] border border-[#EAEFFE]"
              >
                <AchtungIcon />
              </button>
            </div>
          </div>
          <div className="text-[22px] text-[#11142D] leading-[1] mb-1">
            {addPriceDelimiters(price)} Kč{showPerMonth ? `/${t('month')}` : ''}
          </div>
          <div className="mb-3">
            {showMeterPrice && (
              <div className="text-[14px] text-[#8C8C8C] leading-[1] mb-1">
                {addPriceDelimiters(meterPrice)} Kč/m2
              </div>
            )}
          </div>
          {!!lang && (
            <p className="text-[14px] text-[#5D5D5D]">{description?.[lang]}</p>
          )}
        </div>
        {showContacts ? (
          <div
            onClick={preventPropagation}
            className="flex items-center justify-between gap-1 mb-4 mr-4 fade-in"
          >
            {contactPhone ? (
              <div>
                <span className="text-[#333]">{t('phone_number')}: </span>
                <a href={`tel:${contactPhone}`} className="text-[#006CFB]">
                  {formatPhoneNumber(contactPhone)}
                </a>
              </div>
            ) : (
              <div />
            )}
            {additionalPhone ? (
              <div>
                <span className="text-[#333]">
                  {t('additional_phone_number')}:{' '}
                </span>
                <a href={`tel:${additionalPhone}`} className="text-[#006CFB]">
                  {formatPhoneNumber(additionalPhone)}
                </a>
              </div>
            ) : (
              <div />
            )}
            {owner?.email ? (
              <div>
                <span className="text-[#333]">{t('email')}: </span>
                <a href="mailto:owner.email" className="text-[#006CFB]">
                  {owner.email}
                </a>
              </div>
            ) : (
              <div />
            )}
          </div>
        ) : (
          <div className="flex justify-end">
            <button
              onClick={handleShowContacts}
              className="flex-center h-[48px] px-[70px] bg-[#006CFB] rounded-[8px] text-white font-semibold"
            >
              {t('show_contacts')}
            </button>
          </div>
        )}
      </div>
    </Link>
  );
};

export default PropertyListItem;
