import { create } from 'zustand';

interface IGlobalLoaderState {
  loader: boolean;
  setLoader: (loader: boolean) => void;
}

const useGlobalLoaderStore = create<IGlobalLoaderState>((set) => ({
  loader: false,
  setLoader: (loader) =>
    set((prevState) => ({
      ...prevState,
      loader,
    })),
}));

export default useGlobalLoaderStore;
