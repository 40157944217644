import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { allowedLanguages } from '../common/constants/language';

import Layout from '../pages/base/Layout';
import LayoutMap from '../pages/base/LayoutMap';

import MainPage from '../pages/base/MainPage';

import SearchPage from '../pages/base/SearchPage';

import MapPage from '../pages/base/MapPage';

import ApplicationPage from '../pages/base/ApplicationPage';

import AgenciesPage from '../pages/base/AgenciesPage';

import PropertyPage from '../pages/base/PropertyPage';

import DocumentsPage from '../pages/base/DocumentsPage';

import NotFoundPage from '../pages/NotFoundPage';

const LanguageRedirect: React.FC = () => {
  useEffect(() => {
    const browserLanguage = navigator.language.split('-')[0];

    if (allowedLanguages.includes(browserLanguage)) {
      window.location.replace(`/${browserLanguage}`);
    } else {
      window.location.replace('/cz');
    }
  }, []);
  return null;
};

const LanguageValidator: React.FC = () => {
  const [languageChanged, setLanguageChanged] = useState<boolean>(false);

  const { lang } = useParams<{ lang: string }>();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang && allowedLanguages.includes(lang) && i18n.language !== lang) {
      i18n.changeLanguage(lang).then(() => {
        setLanguageChanged(true);
      });
    } else {
      setLanguageChanged(true);
    }
  }, [lang, i18n]);

  if (lang && !allowedLanguages.includes(lang)) {
    const browserLanguage = navigator.language.split('-')[0];

    if (allowedLanguages.includes(browserLanguage)) {
      window.location.replace(`/${browserLanguage}`);
    } else {
      window.location.replace('/cz');
    }
  }

  return <Outlet key={languageChanged.toString()} />;
};

const allowedTypes = ['rent', 'buy'];

const TypeValidator: React.FC = () => {
  const { lang, type } = useParams<{ lang: string; type: string }>();

  if (!type) {
    return <Navigate to={`/${lang}/search/buy`} />;
  }

  if (!allowedTypes.includes(type)) {
    return <Navigate to="/not-found" />;
  }

  return <Outlet />;
};

const routes = [
  {
    path: '/',
    element: <LanguageRedirect />,
  },
  {
    path: '/:lang',
    element: <LanguageValidator />,
    children: [
      {
        path: '',
        element: <Layout />,
        children: [
          {
            path: '',
            element: <MainPage />,
          },
          {
            path: 'search/:type?',
            element: <TypeValidator />,
            children: [
              {
                path: '',
                element: <SearchPage />,
              },
            ],
          },
          {
            path: 'application',
            element: <ApplicationPage />,
          },
          {
            path: 'agencies',
            element: <AgenciesPage />,
          },
          // {
          //   path: "contacts",
          //   element: <ContactsPage />,
          // },
          {
            path: 'property/:id',
            element: <PropertyPage />,
          },
          {
            path: 'documents',
            element: <DocumentsPage />,
          },
        ],
      },
      {
        path: 'map',
        element: <LayoutMap />,
        children: [
          {
            path: '',
            element: <MapPage />,
          },
        ],
      },
    ],
  },
  {
    path: '/not-found',
    element: <NotFoundPage />,
  },
  {
    path: '*',
    element: <Navigate to="/not-found" />,
  },
];

export default routes;
