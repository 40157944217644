import { create } from 'zustand';

export interface IError {
  id: string;
  message: string;
}

interface IGlobalErrorState {
  errors: IError[];
  setError: (error: string | string[]) => void;
  removeError: (id: string) => void;
}

const generateId = (): string => Math.random().toString(36).substr(2, 9);

const useGlobalErrorStore = create<IGlobalErrorState>((set) => ({
  errors: [],
  setError: (error: string | string[]) =>
    set((prevState) => {
      const newErrors: IError[] = Array.isArray(error)
        ? error.map((message) => ({ id: generateId(), message }))
        : [{ id: generateId(), message: error }];
      return { errors: [...prevState.errors, ...newErrors] };
    }),
  removeError: (id: string) =>
    set((prevState) => ({
      errors: prevState.errors.filter((error) => error.id !== id),
    })),
}));

export default useGlobalErrorStore;
