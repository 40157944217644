import React, { useRef, MouseEvent } from 'react';
import ReactDOM from 'react-dom';

import useGlobalModalStore from '../../stores/useGlobalModalStore';

import CrossIcon from '../../assets/icons/CrossIcon';

const Modal: React.FC = () => {
  const { isOpen, children, onClose, noCross } = useGlobalModalStore();

  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent<HTMLDivElement>) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className="fixed top-0 left-0 bottom-0 right-0 flex-center bg-[#00000040] z-[6000]"
      onClick={handleClickOutside}
    >
      <div
        ref={modalRef}
        className="relative bg-white rounded-[8px] w-auto scale-fade-in"
      >
        <div>{children}</div>
        {!noCross && (
          <div
            className="absolute top-[-30px] right-0 cursor-pointer"
            onClick={onClose}
          >
            <CrossIcon width={24} height={24} color="#666" />
          </div>
        )}
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
