import { create } from 'zustand';

interface IGlobalModalState {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode | null;
  setChildren: (children: React.ReactNode | null) => void;
  noCross: boolean;
  setNoCross: (noCross: boolean) => void;
  onClose: () => void;
}

const useGlobalModalStore = create<IGlobalModalState>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set(() => ({ isOpen })),
  children: null,
  setChildren: (children) => set(() => ({ children })),
  noCross: false,
  setNoCross: (noCross) => set(() => ({ noCross })),
  onClose: () =>
    set(() => ({
      isOpen: false,
      children: null,
      noCross: false,
    })),
}));

export default useGlobalModalStore;
