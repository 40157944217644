import React from 'react';
import classNames from 'classnames';

import usePropertyFiltersStore from '../stores/usePropertyFiltersStore';

interface ITagOption {
  id: string;
  label: string;
}

interface IField {
  name: string;
  label: string;
  tags: ITagOption[];
}

interface ITagSelectFieldProps {
  field: IField;
  multi?: boolean;
}

const TagSelectField: React.FC<ITagSelectFieldProps> = ({
  field,
  multi = true,
}) => {
  const { filters, setFilters } = usePropertyFiltersStore();

  const handleChange = (value: any) => {
    setFilters({ [`FIELD_${field.name}`]: value });
  };

  return (
    <div>
      <div className="mb-2.5 text-[#666]">{field.label}</div>
      <div className="flex gap-[10px] flex-wrap">
        {field.tags?.map((option) => (
          <div
            key={option.id}
            className={classNames(
              'flex-center cursor-pointer rounded-[8px] px-[12px] py-[8px] h-[40px] text-[14px] font-bold',
              {
                'bg-[#f5f5f5] text-[#333]': multi
                  ? !filters[`FIELD_${field.name}`]?.includes(option.id)
                  : filters[`FIELD_${field.name}`] !== option.id,
                'bg-[#006CFB] text-white': multi
                  ? filters[`FIELD_${field.name}`]?.includes(option.id)
                  : filters[`FIELD_${field.name}`] === option.id,
              },
            )}
            onClick={() => {
              if (multi) {
                if (filters[`FIELD_${field.name}`]?.includes(option.id))
                  handleChange(
                    filters[`FIELD_${field.name}`].filter(
                      (v: string) => v !== option.id,
                    ),
                  );
                else {
                  const currentFieldValue =
                    filters[`FIELD_${field.name}`] || [];
                  handleChange([...currentFieldValue, option.id]);
                }
              } else handleChange(option.id);
            }}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagSelectField;
