import React, { useEffect, useState } from 'react';

import useGlobalLoaderStore from '../../stores/useGlobalLoaderStore';

const GlobalLoader: React.FC = () => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const { loader } = useGlobalLoaderStore();

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (loader) {
      timer = setTimeout(() => {
        setShowLoader(true);
      }, 1000);
    } else {
      setShowLoader(false);
    }

    return () => clearTimeout(timer);
  }, [loader]);

  return (
    <>
      {showLoader && (
        <div className="fixed w-screen h-screen flex-center z-[99999] bg-[#00000040]">
          <svg
            className="animate-spin h-16 w-16 text-[#006cfb]"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="2"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default GlobalLoader;
