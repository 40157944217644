import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const AchtungIcon: FC<ISvgProps> = ({
  height = 15,
  width = 19,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.296021 14.4658L9.29602 0.46582L18.296 14.4658H0.296021ZM9.29602 12.3798C9.4656 12.3798 9.60767 12.3282 9.72223 12.2249C9.8368 12.1217 9.89375 11.9937 9.89311 11.8408C9.89246 11.688 9.83518 11.5602 9.72126 11.4576C9.60735 11.3549 9.4656 11.303 9.29602 11.3018C9.12644 11.3007 8.98469 11.3523 8.87078 11.4567C8.75686 11.5611 8.69958 11.6892 8.69893 11.8408C8.69829 11.9925 8.75557 12.1205 8.87078 12.2249C8.98599 12.3294 9.12774 12.381 9.29602 12.3798ZM8.81058 10.4277H9.78146V6.0527H8.81058V10.4277Z"
        fill={color}
      />
    </svg>
  );
};

export default AchtungIcon;
