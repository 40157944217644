import { AxiosResponse } from 'axios';

import axiosInstance from '../../axios';

import { IResponse } from '../../../common/types/response';
import { IProperty, IPropertySearch } from '../../../common/types/dto/property';
import {
  IGetPropertySearchFullResponse,
  IGetPropertySearchPayload,
  IPostPropertyComplaintPayload,
  IPostPropertyComplaintResponse,
} from './types';

export const getPropertySearch = async (payload: IGetPropertySearchPayload) => {
  const response: AxiosResponse<
    IResponse<IGetPropertySearchFullResponse<IPropertySearch>>
  > = await axiosInstance.get('/property/search', {
    params: payload,
  });
  return response.data.data;
};

export const getPropertyById = async (id: string) => {
  const response: AxiosResponse<IResponse<IProperty>> = await axiosInstance.get(
    `/property/${id}`,
  );
  return response.data.data;
};

export const postPropertyComplaint = async (
  id: string,
  payload: IPostPropertyComplaintPayload,
) => {
  const response: AxiosResponse<IResponse<IPostPropertyComplaintResponse>> =
    await axiosInstance.post(`/property/${id}/complaint`, payload);
  return response.data.data;
};
