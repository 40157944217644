import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getPropertySearch } from '../../../api/services/property/requests';

import { ReactComponent as EmptyListImage } from '../../../common/assets/images/empty-search.svg';

import {
  IGetPropertySearchPayload,
  ESortField,
  ESortOrder,
} from '../../../api/services/property/types';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';

import PropertyList from '../../../common/components/PropertyList/PropertyList';

const PropertyLastAdded = () => {
  const [limit, setLimit] = useState(40);

  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { data: properties = { items: [], total: 0 } } = useQuery(
    ['propertiesLast', limit],
    async () => {
      setLoader(true);

      const payload: IGetPropertySearchPayload = {
        page: 1,
        limit,
        sortField: ESortField.CreatedAt,
        sortOrder: ESortOrder.Desc,
      };

      const data = await getPropertySearch(payload);

      setLoader(false);
      return data;
    },
    {
      keepPreviousData: true,
      onError: (error: any) => {
        setError(error.response?.data?.message);
        setLoader(false);
      },
    },
  );

  const handleSearchRent = () => {
    setLimit((prevLimit) => prevLimit + 40);
  };

  return (
    <div>
      <div className="text-[28px] text-[#11142D] leading-[1.14] mb-[35px] fade-in">
        {t('main-section-1')}
      </div>
      <PropertyList items={properties.items} isTiles />
      {properties.items.length === 0 && (
        <div className={'flex flex-col items-center'}>
          <EmptyListImage />
          <div className="text-[20px] text-[#605E76] leading-[1.14] mb-[35px] fade-in">
            {t('empty_list')}
          </div>
        </div>
      )}
      {properties.total >= limit && (
        <div className="flex-center mt-[35px]">
          <button
            onClick={handleSearchRent}
            className="flex-center h-[48px] px-[80px] bg-[#006CFB] rounded-[8px] text-[#ffffff] font-semibold"
          >
            {t('show_more_properties')}
          </button>
        </div>
      )}
    </div>
  );
};

export default PropertyLastAdded;
