import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationCZ from './locales/cz.json';
// import translationRU from './locales/ru.json';

const resources = {
  en: {
    translation: translationEN,
  },
  cz: {
    translation: translationCZ,
  },
  // ru: {
  //   translation: translationRU
  // }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'cz',
  fallbackLng: 'cz',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
