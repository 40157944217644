import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const CheckIcon: FC<ISvgProps> = ({
  height = 16,
  width = 16,
  color = '#465FF1',
  opacity = 1,
  strokeWidth = 1.5,
  className,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeOpacity={opacity}
    >
      <path
        d="M13.3334 4L6.00008 11.3333L2.66675 8"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
