import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getSubTree } from '../../../common/utils/helpers';

import usePropertySearchStore from '../../../common/stores/usePropertySearchStore';
import usePropertyStore from '../../../common/stores/usePropertyStore';
import { ReactComponent as EmptyListImage } from '../../../common/assets/images/empty-search.svg';

import usePropertyCategories from '../../../common/hooks/usePropertyCategories';

import PropertyList from '../../../common/components/PropertyList/PropertyList';
import PropertySort from '../../../common/components/PropertySort/PropertySort';

import MapIcon from '../../../common/assets/icons/MapIcon';
import ListIcon from '../../../common/assets/icons/ListIcon';
import TilesIcon from '../../../common/assets/icons/TilesIcon';

const PropertySearchList = () => {
  const [listTiles, setListTiles] = useState(false);
  const navigate = useNavigate();
  const { lang } = useParams();
  const { t } = useTranslation();

  const { propertyTypes, address } = usePropertySearchStore();
  const { properties, total, setLimit } = usePropertyStore();

  const { categories } = usePropertyCategories();

  const title = useMemo(() => {
    const currentType = propertyTypes.path[0];
    const entities = getSubTree(categories, propertyTypes.path)?.entities || [];
    const entityName =
      entities.find((ent) => ent.id === propertyTypes.entities[0]?.id)?.title ||
      '';

    const parts = [
      currentType === 'rent' ? t('rent') : t('sale'),
      entityName ? `of ${entityName}s` : '',
      address.city ? `in ${address.city}` : '',
    ];

    return parts.filter(Boolean).join(' ');
  }, [propertyTypes.path, propertyTypes.entities, categories, address.city, t]);

  const handleMapRedirect = () => {
    navigate(`/${lang}/map`);
  };

  const setMode = (isTiles: boolean) => {
    setListTiles(isTiles);
    setLimit(isTiles ? 40 : 15);
  };

  return (
    <div>
      <div className="mb-[25px]">
        <div className="flex items-center justify-between">
          <div>
            <div className="text-[28px] text-[#11142D] mb-2.5">{title}</div>
            <div className="flex items-center gap-4">
              <div className="text-[14px] text-[#000]">
                {total} {t('listings_found')}
              </div>
              <PropertySort />
              <button
                onClick={handleMapRedirect}
                className="flex-center gap-1.5 h-[26px] px-[18px] text-[14px] text-[#006CFB] font-medium bg-[#EAEFFE] rounded-[8px]"
              >
                <MapIcon width={12} height={12} />
                <span>{t('map_view')}</span>
              </button>
            </div>
          </div>
          <div className="flex items-center gap-[15px]">
            <button onClick={() => setMode(false)}>
              <ListIcon {...(!listTiles && { color: '#006CFB' })} />
            </button>
            <button onClick={() => setMode(true)}>
              <TilesIcon {...(listTiles && { color: '#006CFB' })} />
            </button>
          </div>
        </div>
      </div>
      {properties.length === 0 && (
        <div className={'flex flex-col items-center'}>
          <EmptyListImage />
          <div className="text-[20px] text-[#605E76] leading-[1.14] mb-[35px] fade-in">
            {t('empty_list')}
          </div>
        </div>
      )}
      <PropertyList items={properties} isTiles={listTiles} />
    </div>
  );
};

export default PropertySearchList;
