import React from 'react';

interface IRowCardProps {
  number: string;
  title: string;
  text: string;
}

const RowCard: React.FC<IRowCardProps> = ({ number, title, text }) => {
  return (
    <div className="row-card flex gap-[20px]">
      <div className="flex-center rounded-full text-primary bg-[#e5f4fd] h-[60px] min-w-[60px] text-[22px] font-semibold">
        {number}
      </div>
      <div>
        <div className="text-[#343F52] text-[20px] font-semibold">{title}</div>
        <div className="text-[#60697B] text-[17px] font-medium">{text}</div>
      </div>
    </div>
  );
};

export default RowCard;
