import React, { useEffect } from 'react';
import classNames from 'classnames';

import useGlobalErrorStore, { IError } from '../../stores/useGlobalErrorStore';
import useNotificationStore from '../../stores/useNotificationStore';

const TIME = 5000; // изменяйте только с временем анимации в tailwind.config.js

interface INotificationItem {
  id: string;
  message: string;
  type: string;
}

const Notification: React.FC = () => {
  const { errors, removeError } = useGlobalErrorStore();
  const { notifications, removeNotification } = useNotificationStore();

  const allNotifications: INotificationItem[] = [
    ...errors.map((error: IError) => ({ ...error, type: 'error' })),
    ...notifications,
  ];

  useEffect(() => {
    allNotifications.forEach((notification) => {
      const timer = setTimeout(() => {
        if (notification.type === 'error') {
          removeError(notification.id);
        } else {
          removeNotification(notification.id);
        }
      }, TIME);

      return () => clearTimeout(timer);
    });
  }, [allNotifications, removeError, removeNotification]);

  return (
    <div className="fixed top-[30px] right-[25px] grid gap-3 z-[7000]">
      {allNotifications
        .slice()
        .reverse()
        .map((notification) => (
          <div
            key={notification.id}
            className={classNames(
              'flex items-center min-w-[358px] h-[42px] rounded-lg text-[15px] pl-4 pr-8 relative',
              {
                'bg-[#fbebeb] text-[#000]': notification.type === 'error',
                'bg-[#fff4db] text-[#000]': notification.type === 'warning',
                'bg-[#e0f7fa] text-[#000]': notification.type === 'info',
                'bg-[#03C15B] text-[#fff]': notification.type === 'success',
              },
              'animate-notification',
            )}
          >
            <span>{notification.message}</span>
            <button
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xl"
              onClick={() =>
                notification.type === 'error'
                  ? removeError(notification.id)
                  : removeNotification(notification.id)
              }
            >
              &times;
            </button>
          </div>
        ))}
    </div>
  );
};

export default Notification;
