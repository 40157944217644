import React, { useState } from 'react';

import ChevronIcon from '../../assets/icons/ChevronIconRounded';

export interface ITab {
  id: number;
  label: string;
  content: React.ReactNode;
}

interface ITabsProps {
  options: ITab[];
}

const Tabs: React.FC<ITabsProps> = ({ options }) => {
  const [activeTab, setActiveTab] = useState<number>(options[0].id);
  const [isFading, setIsFading] = useState<boolean>(false);

  const handleTabChange = (newTabId: number) => {
    if (newTabId === activeTab) return;

    setIsFading(true);
    setTimeout(() => {
      setActiveTab(newTabId);
      setIsFading(false);
    }, 300);
  };

  const handlePreviousTab = () => {
    const currentIndex = options.findIndex((option) => option.id === activeTab);
    const newIndex = currentIndex > 0 ? currentIndex - 1 : options.length - 1;
    handleTabChange(options[newIndex].id);
  };

  const handleNextTab = () => {
    const currentIndex = options.findIndex((option) => option.id === activeTab);
    const newIndex = currentIndex < options.length - 1 ? currentIndex + 1 : 0;
    handleTabChange(options[newIndex].id);
  };

  return (
    <div className="w-full">
      <div className="hidden lg:flex space-x-[25px] mb-[25px]">
        {options.map((option) => (
          <button
            key={option.id}
            className={`flex items-center gap-[15px] flex-1 p-[15px] rounded-[20px] font-bold transition-all duration-300 ${
              activeTab === option.id
                ? 'bg-primary text-white'
                : 'bg-[#F1F4FE] text-primary'
            }`}
            onClick={() => handleTabChange(option.id)}
          >
            <div
              className={`flex-center w-[46px] h-[46px] rounded-full ${
                activeTab === option.id
                  ? 'bg-white text-primary'
                  : 'bg-primary text-white'
              }`}
            >
              {option.id}
            </div>
            <div>{option.label}</div>
          </button>
        ))}
      </div>
      <div className="relative">
        <div
          className={`transition-opacity duration-300 ${
            isFading ? 'opacity-0' : 'opacity-100'
          }`}
        >
          {options.find((option) => option.id === activeTab)?.content}
        </div>
        <button
          onClick={handlePreviousTab}
          className="absolute top-1/2 transform -translate-y-1/2 -translate-x-1/2 left-0 flex-center w-[29px] h-[29px] lg:w-[32px] lg:h-[32px] bg-white rounded-full shadow-[0_0_10.4px_0_#00000033] cursor-pointer"
        >
          <ChevronIcon color="#000000" />
        </button>
        <button
          onClick={handleNextTab}
          className="absolute top-1/2 transform -translate-y-1/2 translate-x-1/2 right-0 flex-center w-[32px] h-[32px] bg-white rounded-full shadow-[0_0_10.4px_0_#00000033] cursor-pointer"
        >
          <ChevronIcon className="transform rotate-180" color="#000000" />
        </button>
      </div>
    </div>
  );
};

export default Tabs;
