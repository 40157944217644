import { useTranslation } from 'react-i18next';

import usePropertySearchStore from '../../stores/usePropertySearchStore';

import PropertyTypeSelector from './PropertyTypeSelector';
import PropertyRangeSelector from './PropertyRangeSelector';
import PropertyAddressInput from './PropertyAddressInput';

const PropertySearchBar = ({ className = '' }) => {
  const { t } = useTranslation();

  const { area, setArea, price, setPrice } = usePropertySearchStore();

  return (
    <div
      className={`flex items-center gap-[10px] w-full h-[48px] bg-white border border-[#006CFB] rounded-[8px] px-[18px] ${className}`}
    >
      <PropertyTypeSelector />
      <PropertyRangeSelector
        placeholder={t('placeholder-area')}
        unit="m²"
        defaultValue={area}
        onChange={setArea}
      />
      <PropertyRangeSelector
        placeholder={t('placeholder-price')}
        unit="Kč"
        defaultValue={price}
        onChange={setPrice}
      />
      <PropertyAddressInput />
    </div>
  );
};

export default PropertySearchBar;
