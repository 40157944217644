import React, { useState, useRef, useEffect, ReactNode } from 'react';

interface IDropdownProps {
  children: ReactNode;
  title?: string;
  className?: string;
  buttonClass?: string;
  menuClass?: string;
}

const Dropdown: React.FC<IDropdownProps> = ({
  children,
  title = 'Toggle Dropdown',
  className = '',
  buttonClass = '',
  menuClass = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current?.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={`relative inline-block ${className}`}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center justify-between w-full h-full pl-2 pr-[26px] cursor-pointer ${buttonClass}`}
      >
        <div className="truncate">{title}</div>
        <div
          className={`absolute top-1/2 right-[10px] transform -translate-y-1/2 rotate-45 w-[8px] h-[8px] border-r border-b border-[#808191] -mt-[2px]`}
        />
      </button>
      <div
        className={`absolute top-full left-0 z-[1000] ${isOpen ? '' : 'hidden'} min-w-full bg-white p-0 mt-[15px] rounded-[8px] border border-[#E2E8F0] overflow-hidden fade-in animation-fast ${menuClass}`}
      >
        <div className="max-h-[600px] overflow-auto">{children}</div>
      </div>
    </div>
  );
};

export default Dropdown;
