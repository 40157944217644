import { useEffect, useState } from 'react';

import usePropertySearchStore from '../../common/stores/usePropertySearchStore';

import Container from '../../common/components/Container/Container';

import PropertySearchSection from '../../features/main/components/PropertySearchSection';
import PropertyLastAdded from '../../features/main/components/PropertyLastAdded';

const MainPage = () => {
  const [isInitial, setIsInitial] = useState(true);
  const [resetKey, setResetKey] = useState(0);

  const { reset } = usePropertySearchStore();

  useEffect(() => {
    reset();
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsInitial(false);
      setResetKey((prevKey) => prevKey + 1);
    }, 0);
  }, [reset]);

  return (
    !isInitial && (
      <div>
        <div className="mb-[46px]">
          <PropertySearchSection key={resetKey} />
        </div>
        <Container>
          <div className="mb-[54px]">
            <PropertyLastAdded />
          </div>
        </Container>
      </div>
    )
  );
};

export default MainPage;
