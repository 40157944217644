import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { getPropertySearch } from '../../api/services/property/requests';

import { ESortField, ESortOrder } from '../../api/services/property/types';

import useGlobalLoaderStore from '../stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../stores/useGlobalErrorStore';
import usePropertySearchStore from '../stores/usePropertySearchStore';
import usePropertyStore from '../stores/usePropertyStore';

const usePropertySearch = (isMap = false) => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const {
    propertyTypes,
    area,
    price,
    address,
    osmId,
    coordinates,
    sort,
    rooms,
    bedRooms,
    filters,
  } = usePropertySearchStore();
  const { limit, page, setProperties, setRefetch } = usePropertyStore();

  const { refetch } = useQuery(
    [
      'properties',
      page,
      limit,
      filters,
      coordinates.lat,
      coordinates.lng,
      coordinates.radius,
      isMap,
      propertyTypes.entities,
      area,
      price,
      address,
      osmId,
      sort,
      rooms,
      bedRooms,
    ],
    async () => {
      const categories = propertyTypes.entities.map((entity) => entity.id);
      const sortParams =
        sort === 'default' ? ['createdAt', 'DESC'] : sort.split('_');
      const sortField: ESortField = sortParams[0] as ESortField;
      const sortOrder: ESortOrder = sortParams[1] as ESortOrder;

      if (!isMap) {
        setLoader(true);
      }

      const payload = {
        page,
        limit: isMap ? 10000 : limit,

        ...filters,

        sortField,
        sortOrder,

        ...(isMap && {
          locationPoint: `${coordinates.lat}, ${coordinates.lng}`,
          locationRadius: coordinates.radius,
        }),

        ...(osmId && { osmIds: osmId }),
        ...(categories.length && { categories }),
        ...(rooms.length && { FIELD_roomsCountFlat: rooms }),
        ...(bedRooms.length && { FIELD_roomsCountHouse: bedRooms }),
        ...(area.from?.length && { FIELD_totalPropertyAreaFrom: area.from }),
        ...(area.to?.length && { FIELD_totalPropertyAreaTo: area.to }),
        ...(price.from?.length && { priceFrom: Number(price.from) }),
        ...(price.to?.length && { priceTo: Number(price.to) }),
        // ...address.country?.length && { country: address.country },
        // ...address.postalCode && { postalCode: address.postalCode },
        // ...address.city && { city: address.city },
        // ...address.street && { street: address.street },
        // ...address.houseNumber && { houseNumber: address.houseNumber },
      };

      const data = await getPropertySearch(payload);

      setLoader(false);

      return data;
    },
    {
      enabled: !!propertyTypes.entities.length,
      onSuccess: (data) => setProperties(data),
      onError: (error: any) => {
        setError(error.response?.data?.message);
        setLoader(false);
      },
    },
  );

  useEffect(() => {
    setRefetch(refetch);
  }, [refetch, setRefetch]);
};

export default usePropertySearch;
