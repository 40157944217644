import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import usePropertyFiltersStore from '../stores/usePropertyFiltersStore';

interface IField {
  name: string;
  label: string;
  meta?: {
    unit?: string;
  };
}

interface INumberFieldProps {
  field: IField;
}

const NumberField: React.FC<INumberFieldProps> = ({ field }) => {
  const { t } = useTranslation();

  const { filters, setFilters } = usePropertyFiltersStore();

  const handleFromChange = (values: { value: string }) => {
    setFilters({ [`FIELD_${field.name}From`]: values.value });
  };

  const handleToChange = (values: { value: string }) => {
    setFilters({ [`FIELD_${field.name}To`]: values.value });
  };

  return (
    <div>
      <div className="text-[#5D5D5D] mb-2.5">{field.label}</div>
      <div className="flex items-center gap-6">
        <div className="relative w-[210px] h-[40px] bg-[#F5F5F5] rounded-[8px] px-2.5">
          <NumericFormat
            thousandSeparator=" "
            onValueChange={handleFromChange}
            value={filters[`FIELD_${field.name}From`] || ''}
            placeholder={t('placeholder-from')}
            className="w-full h-full outline-none bg-transparent text-[12px]"
            allowNegative={false}
          />
          {field.meta?.unit && (
            <div className="absolute top-1/2 right-[12px] transform -translate-y-1/2 font-Gilroy text-[12px] select-none text-[#333] font-medium">
              {field.meta?.unit}
            </div>
          )}
        </div>
        <div className="relative w-[210px] h-[40px] bg-[#F5F5F5] rounded-[8px] px-2.5">
          <NumericFormat
            thousandSeparator=" "
            onValueChange={handleToChange}
            value={filters[`FIELD_${field.name}To`] || ''}
            placeholder={t('placeholder-to')}
            className="w-full h-full outline-none bg-transparent text-[12px]"
            allowNegative={false}
          />
          {field.meta?.unit && (
            <div className="absolute top-1/2 right-[12px] transform -translate-y-1/2 font-Gilroy text-[12px] select-none text-[#333] font-medium">
              {field.meta?.unit}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NumberField;
