import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ChooseUsBlock from '../../common/components/ChooseUsBlock';

import ServiceCard from '../../features/application/ServiceCard';

import headerImageEN from '../../common/assets/images/users/header_en.png';
import headerImageCZ from '../../common/assets/images/users/header_cz.png';
import body1ImageEN from '../../common/assets/images/users/body1_en.png';
import body1ImageCZ from '../../common/assets/images/users/body1_cz.png';
import body2ImageEN from '../../common/assets/images/users/body2_en.png';
import body2ImageCZ from '../../common/assets/images/users/body2_cz.png';
import body3ImageEN from '../../common/assets/images/users/body3_en.png';
import body3ImageCZ from '../../common/assets/images/users/body3_cz.png';

const images: { [key: string]: { [key: string]: string } } = {
  cz: {
    header: headerImageCZ,
    map: body1ImageCZ,
    favorites: body2ImageCZ,
    filters: body3ImageCZ,
  },
  en: {
    header: headerImageEN,
    map: body1ImageEN,
    favorites: body2ImageEN,
    filters: body3ImageEN,
  },
  // ru: {
  //   header: headerImageEN,
  //   map: body1ImageEN,
  //   favorites: body2ImageEN,
  //   filters: body3ImageEN,
  // }
};

const ApplicationPage: React.FC = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className=" min-h-screen px-[32px] 2xl:px-[300px] pb-[86px] flex justify-center items-center bg-primary text-white flex-col-reverse sm:flex-row">
        <div className="flex-1 pt-[33px] fade-in">
          <h1 className="text-[46px] font-bold leading-[110%]">
            {t('users-header-title')}
            <br />
            {t('users-header-second-title')}
          </h1>
          <h2 className="text-[26px] mt-[16px]">
            {t('users-header-description')}
          </h2>
        </div>
        <div className="flex-1 fade-in">
          <img src={images[lang as string].header} alt="reelty" />
        </div>
      </div>

      <ChooseUsBlock />

      <div className="px-[32px] 2xl:px-[300px] pb-[54px] pt-[40px] sm:bg-[#f8fbff] flex flex-col items-center">
        <div className="text-primary font-semibold tracking-[0.4px] uppercase leading-[135%]">
          {t('users-features-title')}
        </div>
        <h2 className="text-center text-[42px] font-semibold leading-[120%] mt-[16px]">
          {t('users-features-description')}
        </h2>
        <div className="flex justify-between mt-[52px] flex-col sm:flex-row gap-[34px]">
          <ServiceCard
            image={images[lang as string].map}
            title={t('service-title-1')}
            text={t('service-description-1')}
          />
          <ServiceCard
            image={images[lang as string].favorites}
            title={t('service-title-2')}
            text={t('service-description-2')}
          />
          <ServiceCard
            image={images[lang as string].filters}
            title={t('service-title-3')}
            text={t('service-description-3')}
          />
        </div>
      </div>
    </div>
  );
};

export default ApplicationPage;
