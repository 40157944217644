import { useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';

import usePropertySearchStore from '../../stores/usePropertySearchStore';

import usePropertyFiltersStore from './stores/usePropertyFiltersStore';

interface IFilters {
  FIELD_totalAreaFrom?: string;
  FIELD_totalAreaTo?: string;
  priceFrom?: string;
  priceTo?: string;
  FIELD_roomsCountFlat?: string[];
  FIELD_roomsCountHouse?: string[];
  [key: string]: any;
}

interface IFilterControlsProps {
  onSubmit?: () => void;
}

function countUniqueKeys(obj: IFilters) {
  const uniqueKeys = new Set();

  Object.keys(obj).forEach((key) => {
    const baseKey = key.replace(/(From|To)$/, '');
    uniqueKeys.add(baseKey);
  });

  return uniqueKeys.size;
}

const FilterControls: FC<IFilterControlsProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const {
    area,
    setArea,
    price,
    setPrice,
    rooms,
    setRooms,
    bedRooms,
    setBedRooms,
    setFilters: setSearchFilters,
  } = usePropertySearchStore();

  const { filters, setFilters, reset } = usePropertyFiltersStore();

  const filtersCount = countUniqueKeys(filters);

  const handleApply = () => {
    const {
      FIELD_totalAreaFrom,
      FIELD_totalAreaTo,
      priceFrom,
      priceTo,
      FIELD_roomsCountFlat,
      FIELD_roomsCountHouse,
      ...filteredFilters
    } = filters;

    setPrice({
      from: priceFrom || '',
      to: priceTo || '',
    });

    setArea({
      from: FIELD_totalAreaFrom || '',
      to: FIELD_totalAreaTo || '',
    });

    setRooms(FIELD_roomsCountFlat || []);

    setBedRooms(FIELD_roomsCountHouse || []);

    setSearchFilters(filteredFilters);
    !!onSubmit && onSubmit();
  };

  const handleReset = () => {
    reset();

    setPrice({
      from: '',
      to: '',
    });

    setArea({
      from: '',
      to: '',
    });

    setRooms([]);

    setBedRooms([]);

    !!onSubmit && onSubmit();
  };

  useEffect(() => {
    if (area.to || area.from) {
      setFilters({
        FIELD_totalAreaFrom: area.from,
        FIELD_totalAreaTo: area.to,
      });
    }

    if (price.to || price.from) {
      setFilters({
        priceFrom: price.from,
        priceTo: price.to,
      });
    }

    if (rooms.length) {
      setFilters({
        FIELD_roomsCountFlat: rooms,
      });
    }

    if (bedRooms.length) {
      setFilters({
        FIELD_roomsCountHouse: bedRooms,
      });
    }
    // eslint-disable-next-line
  }, [price, area, rooms, bedRooms]);

  return (
    <div className="flex items-center justify-end gap-5">
      <button
        onClick={handleReset}
        className="text-[16px] text-[#006CFB] font-semibold"
      >
        {t('reset_all')}
      </button>
      <button
        onClick={handleApply}
        className="flex-center w-[188px] h-[48px] bg-[#006CFB] rounded-[8px] text-[16px] text-[#FFFFFF] font-semibold"
      >
        {t('apply')} (<span>{filtersCount}</span>)
      </button>
    </div>
  );
};

export default FilterControls;
