import { useTranslation } from 'react-i18next';

import {
  footerBuyItems,
  footerMenuItems,
  footerRentItems,
} from '../../constants/menu';

import Menu from '../Menu/Menu';
import Container from '../Container/Container';

import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as DownloadAppStoreImage } from '../../assets/images/downloadStoreImage.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-primary text-white py-[25px]">
      <Container>
        <div className="flex flex-col lg:flex-row lg:justify-between gap-[60px] lg:gap-[25px] mb-[60px] lg:mb-[35px]">
          <div className="flex flex-col lg:flex-row items-center gap-[60px] lg:gap-[71px]">
            <div className="flex flex-col lg:justify-between gap-[15px] lg:gap-[46px] w-full lg:max-w-[213px]">
              <div className="flex items-center justify-between w-full">
                <div>
                  <Logo width={134} height={46} />
                </div>
                <div className="lg:hidden">
                  <a href="https://apps.apple.com/us/app/reelty/id6602896278">
                    <DownloadAppStoreImage />
                  </a>
                </div>
              </div>
              <div className="text-body-small">{t('footer_description')}</div>
              <div className="flex-center">
                <div className="flex items-center gap-[15px]">
                  <a
                    href="https://www.facebook.com/profile.php?id=61563085007566"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/reelty.cz/#"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                </div>
              </div>
            </div>
            <div className="flex gap-[40px] sm:gap-[75px]">
              <div className="flex flex-col gap-[15px]">
                <h5 className="text-heading-5 text-white">
                  {t('footer-menu')}
                </h5>
                <Menu
                  items={footerMenuItems}
                  className="flex flex-col gap-[10px] text-white"
                />
              </div>
              <div className="flex flex-col gap-[15px]">
                <h5 className="text-heading-5 text-white">{t('rent')}</h5>
                <Menu
                  items={footerRentItems}
                  className="flex flex-col gap-[10px] text-[#CACACA]"
                />
              </div>
              <div className="flex flex-col gap-[15px]">
                <h5 className="text-heading-5 text-white">{t('buy')}</h5>
                <Menu
                  items={footerBuyItems}
                  className="flex flex-col gap-[10px] text-[#CACACA]"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between items-center lg:items-end">
            <div className="hidden lg:block">
              <a href="https://apps.apple.com/us/app/reelty/id6602896278">
                <DownloadAppStoreImage />
              </a>
            </div>
            <div className="flex flex-col items-center lg:items-end gap-[5px]">
              <h6 className="text-heading-6 text-white">
                {t('footer-get-in-touch')}
              </h6>
              <div className="text-body-small text-right">
                {t('footer-address')}
              </div>
              <div>
                <a href="mailto:info@konspirecreative.cz">
                  <div className="text-body-small">{t('footer-email')}</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="text-body-smaller text-center">
          {t('footer-rights')} |{' '}
          <a href="/personal_data.pdf" download="personal_data.pdf">
            {t('personal_data')}
          </a>{' '}
          |{' '}
          <a href="/GTC_users.pdf" download="GTC_users.pdf">
            {t('gtc_users')}
          </a>{' '}
          |{' '}
          <a
            href="/Complaints_procedure.pdf"
            download="Complaints_procedure.pdf"
          >
            {t('complaints_procedure')}
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
