export const RENT_LONGTERM_APARTMENT = 'rent.longTerm.residental.apartment';
export const RENT_LONGTERM_HOUSE = 'rent.longTerm.residental.house';
export const RENT_LONGTERM_ROOM = 'rent.longTerm.residental.room';
export const RENT_LONGTERM_GARAGE = 'rent.longTerm.residental.garage';
export const RENT_LONGTERM_OFFICE = 'rent.longTerm.commercial.office';

export const SALE_APARTMENT = 'sale.residental.apartment';
export const SALE_HOUSE = 'sale.residental.house';
export const SALE_GARAGE = 'sale.residental.garage';
export const SALE_OFFICE = 'sale.commercial.office';
