import React from 'react';
import { useTranslation } from 'react-i18next';

import Accordion from '../../common/components/Accordion/Accordion';

const AgenciesFAQ: React.FC = () => {
  const { t } = useTranslation();

  const items = [
    {
      title: t('faq_title_1'),
      content: t('faq_text_1'),
    },
    {
      title: t('faq_title_2'),
      content: t('faq_text_2'),
    },
    {
      title: t('faq_title_3'),
      content: t('faq_text_3'),
    },
    {
      title: t('faq_title_4'),
      content: t('faq_text_4'),
    },
  ];

  return <Accordion items={items} />;
};

export default AgenciesFAQ;
