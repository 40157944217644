import { useParams } from 'react-router-dom';

import { capitalizeFirstLetter } from '../../common/utils/helpers';

interface IPropertyFieldsColumnProps {
  items?:
    | {
        label?: string;
        value: any;
        unit?: string;
      }[]
    | null;
  columnCount?: number;
}

const PropertyFieldsColumn = ({
  items,
  columnCount = 1,
}: IPropertyFieldsColumnProps) => {
  const { lang } = useParams();

  if (!items) return null;
  return (
    <div className={`grid grid-cols-${columnCount} gap-[16px]`}>
      {items
        ?.filter((f) => !!f.value)
        .map((field, index) => {
          const isIsoDate =
            typeof field.value === 'string' &&
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(field.value);
          const isMoney =
            typeof field.value === 'string' && field?.unit?.includes('Kč');

          const formattedValue =
            typeof field.value === 'object' && field.value !== null
              ? field.value[lang as string]
              : isIsoDate
                ? new Intl.DateTimeFormat('default', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }).format(new Date(field.value))
                : isMoney
                  ? field.value?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  : field.value;

          return (
            <div className="flex gap-[8px] leading-[1.3]" key={index}>
              <div className="flex-1 text-[#5D5D5D]">
                {capitalizeFirstLetter(field.label)}
              </div>
              <div className="flex-1 text-[#333333]">
                {formattedValue} {field.unit}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PropertyFieldsColumn;
