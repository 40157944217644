import { useTranslation } from 'react-i18next';

import Container from '../../common/components/Container/Container';

import LeadButtons from './LeadButtons';

const AgenciesHeroSection = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-primary">
      <Container>
        <div className="md:h-screen md:max-h-[700px] flex flex-col md:justify-end pb-[25px] md:pb-[100px]">
          <div className="py-[187px] md:py-0 mb-[25px]">
            <h1 className="text-[45px] leading-[57px] md:text-[80px] text-white font-bold md:leading-[109px] mb-[25px]">
              {t('get_free_listings')}
            </h1>
            <div className="text-[22px] leading-[30px] md:text-[28px] text-white md:leading-[38px] font-semibold">
              {t('join_reelty')}
            </div>
          </div>
          <LeadButtons variant="secondary" />
        </div>
      </Container>
    </div>
  );
};

export default AgenciesHeroSection;
