import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';

import usePropertyFiltersStore from '../stores/usePropertyFiltersStore';

const PriceHardcodedField = () => {
  const { t } = useTranslation();

  const { filters, setFilters } = usePropertyFiltersStore();

  const handleFromChange = (values: any) => {
    setFilters({ priceFrom: values.value });
  };

  const handleToChange = (values: any) => {
    setFilters({ priceTo: values.value });
  };

  return (
    <div>
      <div className="text-[#5D5D5D] mb-2.5">{t('placeholder-price')}</div>
      <div className="flex items-center gap-6">
        <div className="relative w-[210px] h-[40px] bg-[#F5F5F5] rounded-[8px] px-2.5">
          <NumericFormat
            thousandSeparator=" "
            onValueChange={handleFromChange}
            value={filters[`priceFrom`] || ''}
            placeholder={t('placeholder-from')}
            className="w-full h-full outline-none bg-transparent text-[12px]"
            allowNegative={false}
          />
          <div className="absolute top-1/2 right-[12px] transform -translate-y-1/2 font-Gilroy text-[12px] select-none text-[#333] font-medium">
            Kč
          </div>
        </div>
        <div className="relative w-[210px] h-[40px] bg-[#F5F5F5] rounded-[8px] px-2.5">
          <NumericFormat
            thousandSeparator=" "
            onValueChange={handleToChange}
            value={filters[`priceTo`] || ''}
            placeholder={t('placeholder-to')}
            className="w-full h-full outline-none bg-transparent text-[12px]"
            allowNegative={false}
          />
          <div className="absolute top-1/2 right-[12px] transform -translate-y-1/2 font-Gilroy text-[12px] select-none text-[#333] font-medium">
            Kč
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceHardcodedField;
