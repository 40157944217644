import React, { useRef, useState } from 'react';
import Slider from 'react-slick';

import { formatPhoneNumber } from '../../utils/helpers';

import { IImage } from '../../types/media';

import ImageWithFallback from '../../../common/components/Image/ImageWithFallback';

import ChevronIcon from '../../../common/assets/icons/ChevronIcon';
import CrossIcon from '../../../common/assets/icons/CrossIcon';
import LinkIcon from '../../../common/assets/icons/LinkIcon';

// import { ReactComponent as FullScreenIcon} from "../../../common/assets/icons/fullScreenIcon.svg";

interface ICustomArrowProps {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isLarge?: boolean;
}

interface IPropertyCarouselProps {
  items: IImage[];
  settings?: any;
  withFullScreen?: boolean;
  wrapperClass?: string;
  imageClass?: string;
  data?: {
    property?: string;
    phone?: string;
  };
}

const CustomPrevArrow: React.FC<ICustomArrowProps> = (props) => {
  const { onClick, isLarge = false } = props;
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onClick(e);
  };
  return (
    <div
      className={`opacity-0 group-hover:opacity-100 transition-opacity duration-500 absolute top-1/2 left-[18px] z-10 transform -translate-y-1/2 flex-center p-2 rounded-full cursor-pointer bg-[#8C8C8CD9] ${isLarge ? 'w-[40px] h-[40px]' : 'w-7 h-7'}`}
      onClick={handleClick}
    >
      <ChevronIcon
        width={isLarge ? 16 : 12}
        height={isLarge ? 16 : 14}
        className="mr-0.5"
      />
    </div>
  );
};

const CustomNextArrow: React.FC<ICustomArrowProps> = (props) => {
  const { onClick, isLarge = false } = props;
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onClick(e);
  };
  return (
    <div
      className={`opacity-0 group-hover:opacity-100 transition-opacity duration-500 absolute top-1/2 right-[18px] z-10 transform -translate-y-1/2 flex-center p-2 rounded-full cursor-pointer bg-[#8C8C8CD9] ${isLarge ? 'w-[40px] h-[40px]' : 'w-7 h-7'}`}
      onClick={handleClick}
    >
      <ChevronIcon
        width={isLarge ? 16 : 12}
        height={isLarge ? 16 : 14}
        className="transform rotate-180 ml-1"
      />
    </div>
  );
};

const PropertyCarousel: React.FC<IPropertyCarouselProps> = ({
  items,
  settings,
  withFullScreen = false,
  wrapperClass,
  imageClass,
  data = {},
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isFancyboxOpen, setFancyboxOpen] = useState(false);

  const sliderRef = useRef<Slider | null>(null);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: (index: number) => setCurrentSlide(index),
    arrows: false,
    prevArrow: null,
    nextArrow: null,
    ...settings,
  };

  const openFancybox = (index: number) => {
    setCurrentSlide(index);
    setFancyboxOpen(true);
  };

  const closeFancybox = () => {
    sliderRef.current?.slickGoTo(currentSlide, true);
    setFancyboxOpen(false);
  };

  const prevImage = () => {
    setCurrentSlide((prev) => (prev === 0 ? items.length - 1 : prev - 1));
  };

  const nextImage = () => {
    setCurrentSlide((prev) => (prev === items.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    sliderRef.current?.slickPrev();
  };

  const nextSlide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    sliderRef.current?.slickNext();
  };

  return (
    <div className="relative group slider-container">
      <div className={`absolute w-full ${wrapperClass || 'aspect-[1.52]'}`}>
        <CustomPrevArrow onClick={prevSlide} isLarge={withFullScreen} />
        <CustomNextArrow onClick={nextSlide} isLarge={withFullScreen} />

        <div
          className={`opacity-0 group-hover:opacity-100 transition-opacity duration-500 absolute bottom-2 flex-center text-white rounded-[4px] text-[12px] z-10 bg-[#8C8C8CD9] ${withFullScreen ? 'w-[40px] h-[24px] left-1/2 transform -translate-x-1/2 ' : 'w-[46px] h-[28px] right-2'}`}
        >
          {currentSlide + 1} / {items.length}
        </div>
      </div>
      <Slider
        ref={(slider: Slider | null) => {
          sliderRef.current = slider;
        }}
        {...sliderSettings}
      >
        {items?.map((image, index) => (
          <div
            key={image.id}
            onClick={() => withFullScreen && openFancybox(index)}
            className={`relative outline-none ${wrapperClass || 'aspect-[1.52]'}`}
          >
            <ImageWithFallback
              src={image.url}
              alt={image.name}
              className={`w-full h-full object-cover ${imageClass || 'rounded-[4px]'}`}
            />

            {/*{withFullScreen && (*/}
            {/*  <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-500 absolute top-2 right-2">*/}
            {/*    <button*/}
            {/*      onClick={() => openFancybox(index)}*/}
            {/*      className="flex-center w-[60px] h-[60px] bg-[#40404080] rounded-full"*/}
            {/*    >*/}
            {/*      <FullScreenIcon />*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        ))}
      </Slider>
      {isFancyboxOpen && (
        <div className="fixed inset-0 bg-black flex flex-col items-center justify-between gap-[10px] px-2.5 py-5 z-50 overflow-hidden">
          <div className="flex items-center justify-between w-full py-[2px] px-[30px] bg-gray-900 text-white">
            <div>{data.property}</div>
            <div>
              {currentSlide + 1} / {items.length}
            </div>
            <div className="flex items-center gap-4">
              {!!data.phone && (
                <button className="flex-center w-[156px] h-[26px] bg-[#006CFB] rounded-[8px] text-[14px] font-medium">
                  {formatPhoneNumber(data.phone)}
                </button>
              )}
              <button className="flex-center w-[26px] h-[26px]">
                <LinkIcon width={16} height={18} color="#fff" />
              </button>
              <button
                className="flex-center w-[26px] h-[26px]"
                onClick={closeFancybox}
              >
                <CrossIcon width={18} height={18} color="#fff" />
              </button>
            </div>
          </div>
          <div className="relative w-full flex-1 flex-center py-[30px] overflow-hidden">
            <img
              src={items[currentSlide]?.url}
              alt={`Slide ${currentSlide}`}
              className="w-full h-full object-contain"
            />
            <CustomPrevArrow onClick={prevImage} isLarge={withFullScreen} />,
            <CustomNextArrow onClick={nextImage} isLarge={withFullScreen} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyCarousel;
