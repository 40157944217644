import React, { useLayoutEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { headerMenuItems } from '../../constants/menu';

import Menu from '../Menu/Menu';
import Container from '../Container/Container';
import LangSwitcher from '../LangSwitcher/LangSwitcher';

import { ReactComponent as BurgerIcon } from '../../assets/icons/burgerIcon.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import CrossIcon from '../../assets/icons/CrossIcon';

const Header = () => {
  const [isReady, setIsReady] = useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  const { lang } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const toggleBurgerOpen = () => {
    setIsBurgerOpen((isBurgerOpen) => !isBurgerOpen);
  };

  const isActivePath = (href: string): boolean => {
    if (href === '') {
      return pathname === `/${lang}/` || pathname === `/${lang}`;
    }

    const regex = new RegExp(`^/${lang}${href}(?:/|$)`);
    return regex.test(pathname);
  };

  const menuItemsMap = headerMenuItems.map(({ label, href }) => {
    return (
      <Link key={label} to={`/${lang}${href}`}>
        <div
          onClick={() => setIsBurgerOpen(false)}
          className={`${isActivePath(href) ? 'bg-[#F2F8FF]' : 'bg-transparent'} flex items-center px-[33px] h-[42px] text-200 text-body hover:bg-[#F2F8FF]`}
          title={t(label)}
        >
          {t(label)}
        </div>
      </Link>
    );
  });

  useLayoutEffect(() => {
    document.fonts.ready.finally(() => {
      setIsReady(true);
    });
  }, []);

  return (
    <header className="relative z-[1000] bg-primary">
      <Container>
        <div className="flex justify-between items-center h-[85px] fade-in">
          <div>
            <Link to={`/${lang}`}>
              <Logo width={134} height={47} />
            </Link>
          </div>
          {isReady && (
            <div className="hidden lg:flex items-center gap-[32px]">
              <div className="hidden lg:block">
                <Menu items={headerMenuItems} />
              </div>
              <div className="hidden lg:flex">
                <LangSwitcher />
              </div>
            </div>
          )}
          <button onClick={toggleBurgerOpen} className="flex-center lg:hidden">
            {isBurgerOpen ? (
              <CrossIcon color="white" strokeWidth={2} />
            ) : (
              <BurgerIcon />
            )}
          </button>
        </div>
      </Container>
      <div
        className={`fixed top-0 left-0 bottom-0 w-[265px] py-[23px] bg-white transition-transform duration-500 transform ${isBurgerOpen ? 'translate-x-0' : '-translate-x-[265px]'}`}
      >
        {menuItemsMap}
      </div>
    </header>
  );
};

export default Header;
