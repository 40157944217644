import React from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  addPriceDelimiters,
  generatePropertyDataString,
  getAddressString,
} from '../../utils/helpers';

import { IPropertySearch } from '../../types/dto/property';

import NoImageIcon from '../../assets/icons/NoImageIcon';

// import LikeIcon from "../../assets/icons/LikeIcon";

interface IPropertyTileProps {
  item: IPropertySearch;
}

//todo: check translate after all done
const PropertyTile: React.FC<IPropertyTileProps> = ({
  item: { id, mainImage, price, address, fields, category },
}) => {
  const { lang } = useParams();

  const addressString = getAddressString(address);

  const dataString = generatePropertyDataString(category, fields);

  return (
    <Link
      className="relative flex flex-col rounded-[8px] hover:bg-[#EAEFFE59] cursor-pointer fade-in"
      to={`/${lang}/property/${id}`}
    >
      {mainImage?.url ? (
        <img
          src={mainImage.url}
          alt={mainImage.name}
          className="w-full aspect-[1.23] rounded-[8px_8px_0_0] object-cover"
        />
      ) : (
        <div className="flex-center aspect-[1.23] bg-[#F8F8F8] rounded-[8px_8px_0_0]">
          <NoImageIcon width={40} height={40} />
        </div>
      )}
      {/*<div className="absolute top-[10px] right-[10px] flex-center w-5 h-5 rounded-full bg-[#40404080]">*/}
      {/*  <LikeIcon width={12} height={12} fill={isFavorite ? 'white': 'none'} />*/}
      {/*</div>*/}
      <div className="flex-1 flex flex-col gap-2 p-3 border border-t-0 border-[#EAEFFE] rounded-[0_0_8px_8px]">
        <div className="text-[22px] text-[#11142D] leading-[1]">
          {addPriceDelimiters(price)} Kč
        </div>
        <div className="text-[#006CFB] leading-[1]">{dataString}</div>
        <div className="text-[#5D5D5D] leading-[1]">{addressString}</div>
      </div>
    </Link>
  );
};

export default PropertyTile;
