import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LeadButtons from './LeadButtons';

import FingerPrintIcon from '../../common/assets/icons/FingerPrintIcon';
import PresentIcon from '../../common/assets/icons/PresentIcon';
import APostIcon from '../../common/assets/icons/APostIcon';

import { ReactComponent as AgenciesDiagramImageGray } from '../../common/assets/images/agencies/agenciesDiagram_gray.svg';
import { ReactComponent as AgenciesDiagramImageBlue } from '../../common/assets/images/agencies/agenciesDiagram_blue.svg';
import { ReactComponent as AgenciesDiagramImageGrayMob } from '../../common/assets/images/agencies/agenciesDiagramMob_gray.svg';
import { ReactComponent as AgenciesDiagramImageBlueMob } from '../../common/assets/images/agencies/agenciesDiagramMob_blue.svg';

const DiagramCard = ({
  icon,
  title,
  text,
}: {
  icon: React.ReactNode;
  title: string;
  text: string;
}) => {
  return (
    <div className="w-[290px] xl:w-[438px] p-[15px] bg-[#F1F4FE] rounded-[20px]">
      <div className="flex items-center gap-[15px] mb-[15px]">
        <div>
          <div className="flex-center w-[46px] h-[46px] bg-primary rounded-full">
            <div>{icon}</div>
          </div>
        </div>
        <div className="text-heading-4 xl:text-heading-3 text-primary">
          {title}
        </div>
      </div>
      <div className="text-body-small xl:text-body-default">{text}</div>
    </div>
  );
};

const AgenciesDiagram: React.FC = () => {
  const [scrollPercent, setScrollPercent] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const container = document.getElementById('diagram-container');
      if (container) {
        const { top } = container.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        const startScroll = windowHeight / 4;
        const endScroll = windowHeight * 1.6;

        if (top < endScroll) {
          const progress = Math.max(
            0,
            Math.min(
              1,
              (windowHeight - top - startScroll) / (endScroll - startScroll),
            ),
          );
          setScrollPercent(progress);
        } else {
          setScrollPercent(0);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div id="diagram-container" className="relative h-[905px] md:h-[1120px]">
      <div className="md:hidden">
        <AgenciesDiagramImageGrayMob className="-top-[100px] absolute z-10 h-[1005px]" />

        <div
          className="absolute -top-[100px] inset-0 z-20 pointer-events-none overflow-hidden"
          style={{
            height: `${scrollPercent * 100}%`,
          }}
        >
          <AgenciesDiagramImageBlueMob className="h-[1005px]" />
        </div>
        <div className="absolute top-[160px] left-[50px] transform -translate-y-1/2 z-[100]">
          <DiagramCard
            icon={<FingerPrintIcon />}
            title={t('diagram_title_1')}
            text={t('diagram_text_1')}
          />
        </div>
        <div className="absolute top-[350px] left-[50px] transform -translate-y-1/2 z-[100]">
          <DiagramCard
            icon={<PresentIcon />}
            title={t('diagram_title_2')}
            text={t('diagram_text_2')}
          />
        </div>
        <div className="absolute top-[570px] left-[50px] transform -translate-y-1/2 z-[100]">
          <DiagramCard
            icon={<APostIcon />}
            title={t('diagram_title_3')}
            text={t('diagram_text_3')}
          />
        </div>
        <div className="absolute top-[690px] left-[50px] z-[100]">
          <LeadButtons gap="diagram" />
        </div>
      </div>
      <div className="hidden md:block">
        <AgenciesDiagramImageGray className="relative z-10 w-full h-[1120px]" />

        <div
          className="absolute inset-0 z-20 pointer-events-none overflow-hidden"
          style={{
            height: `${scrollPercent * 100}%`,
          }}
        >
          <AgenciesDiagramImageBlue className="w-full h-[1120px]" />
        </div>
        <div className="absolute top-[240px] left-[calc(50%+50px)] lg:left-[calc(50%+103px)] transform -translate-y-1/2 z-[100]">
          <DiagramCard
            icon={<FingerPrintIcon />}
            title={t('diagram_title_1')}
            text={t('diagram_text_1')}
          />
        </div>
        <div className="absolute top-[530px] left-[calc(50%-50px)] lg:left-[calc(50%-103px)] transform -translate-x-full -translate-y-1/2 z-[100]">
          <DiagramCard
            icon={<PresentIcon />}
            title={t('diagram_title_2')}
            text={t('diagram_text_2')}
          />
        </div>
        <div className="absolute top-[830px] left-[calc(50%+50px)] lg:left-[calc(50%+103px)] transform -translate-y-1/2 z-[100]">
          <DiagramCard
            icon={<APostIcon />}
            title={t('diagram_title_3')}
            text={t('diagram_text_3')}
          />
        </div>
        <div className="absolute top-[1065px] left-[calc(50%-295px)] z-[100]">
          <LeadButtons gap="diagram" />
        </div>
      </div>
    </div>
  );
};

export default AgenciesDiagram;
