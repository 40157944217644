import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const NoImageIcon: FC<ISvgProps> = ({
  height = 60,
  width = 60,
  color = '#333',
  opacity = 1,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill={color}
      opacity={opacity}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M56.25 6.40125L53.5988 3.75L3.75 53.5988L6.40125 56.25L10.1512 52.5H48.75C49.7441 52.4985 50.6971 52.1029 51.4 51.4C52.1029 50.6971 52.4985 49.7441 52.5 48.75V10.1512L56.25 6.40125ZM48.75 48.75H13.9012L28.5131 34.1381L32.9738 38.5988C33.677 39.3018 34.6306 39.6967 35.625 39.6967C36.6194 39.6967 37.573 39.3018 38.2762 38.5988L41.25 35.625L48.75 43.1194V48.75ZM48.75 37.815L43.9012 32.9663C43.198 32.2632 42.2444 31.8683 41.25 31.8683C40.2556 31.8683 39.302 32.2632 38.5988 32.9663L35.625 35.94L31.1681 31.4831L48.75 13.9012V37.815ZM11.25 41.25V35.625L20.625 26.2556L23.1994 28.8319L25.8544 26.1769L23.2762 23.5988C22.573 22.8957 21.6194 22.5008 20.625 22.5008C19.6306 22.5008 18.677 22.8957 17.9738 23.5988L11.25 30.3225V11.25H41.25V7.5H11.25C10.2554 7.5 9.30161 7.89509 8.59835 8.59835C7.89509 9.30161 7.5 10.2554 7.5 11.25V41.25H11.25Z" />
    </svg>
  );
};

export default NoImageIcon;
