import React from 'react';
import ReactDOM from 'react-dom/client';

import I18nProvider from './i18n/I18nProvider';
import App from './App';

import './index.css';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <I18nProvider>
        <App />
      </I18nProvider>
    </React.StrictMode>,
  );
} else {
  console.error('Root element not found');
}
