import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { addPriceDelimiters } from '../../utils/helpers';

import Dropdown from '../Dropdown/Dropdown';

interface IValue {
  from: string;
  to: string;
}

interface IPropertyRangeSelectorProps {
  placeholder?: string;
  unit?: string;
  defaultValue?: IValue;
  onChange: (value: IValue) => void;
}

const isCorrectValue = (value: IValue): boolean => {
  const { from, to } = value;

  if (!from || !to) return true;

  return +from <= +to;
};

const PropertyRangeSelector: React.FC<IPropertyRangeSelectorProps> = ({
  placeholder = '',
  unit = '',
  defaultValue = {
    from: '',
    to: '',
  },
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);

  const { t } = useTranslation();

  const valueToString = () => {
    if (!value.to && !value.from) {
      return placeholder;
    }

    if (!isCorrectValue(value)) {
      return t('incorrect_interval');
    }

    return `${!value.to?.length ? t('placeholder-from') + ': ' : ''}${value.from?.length ? addPriceDelimiters(value.from) + ' ' + unit : t('placeholder-to') + ': '}${value.to?.length ? ' - ' + addPriceDelimiters(value.to) + ' ' + unit : ''}`;
  };

  const handleFromChange: NumericFormatProps['onValueChange'] = (values) => {
    const { value } = values;

    setValue((prevValue) => ({
      ...prevValue,
      from: value,
    }));
  };

  const handleToChange: NumericFormatProps['onValueChange'] = (values) => {
    const { value } = values;

    setValue((prevValue) => ({
      ...prevValue,
      to: value,
    }));
  };

  useEffect(() => {
    if (isCorrectValue(value)) {
      onChange(value);
    }
  }, [value, onChange]);

  return (
    <div>
      <Dropdown
        className="w-[170px] h-[32px] after:absolute after:top-2 after:right-0 after:block after:content-[''] after:w-[1px] after:h-[16px] after:bg-[#D9D9D9]"
        buttonClass="text-[12px] text-[#8C8C8C] font-semibold"
        title={valueToString() || placeholder}
      >
        <div className="flex items-center gap-[15px] w-[255px] py-[13px] px-[10px]">
          <div className="relative">
            <NumericFormat
              thousandSeparator=" "
              onValueChange={handleFromChange}
              value={value.from || ''}
              step={1}
              className="w-full h-[40px] border border-[#E2E8F0] rounded-[5px] outline-none pl-[6px] pr-[28px] text-[12px] text-[#666666] placeholder-[#AAAAAA80]"
              placeholder={t('placeholder-from')}
              allowNegative={false}
            />
            <div className="absolute top-1/2 right-[6px] transform -translate-y-1/2 text-[12px] text-[#333333]">
              {unit}
            </div>
          </div>
          <div className="relative">
            <NumericFormat
              thousandSeparator=" "
              onValueChange={handleToChange}
              value={value.to || ''}
              step={1}
              className="w-full h-[40px] border border-[#E2E8F0] rounded-[5px] outline-none pl-[6px] pr-[28px] text-[12px] text-[#666666] placeholder-[#AAAAAA80]"
              placeholder={t('placeholder-to')}
              allowNegative={false}
            />
            <div className="absolute top-1/2 right-[6px] transform -translate-y-1/2 text-[12px] text-[#333333]">
              {unit}
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default PropertyRangeSelector;
