import React from 'react';
import classNames from 'classnames';

import usePropertyFiltersStore from '../stores/usePropertyFiltersStore';

interface IFieldOption {
  value: string;
  label: string;
}

interface IField {
  name: string;
  label: string;
  options?: IFieldOption[];
}

interface IRadioFieldProps {
  field: IField;
  multi?: boolean;
  isSelect?: boolean;
}

const RadioField: React.FC<IRadioFieldProps> = ({
  field,
  multi = true,
  isSelect = false,
}) => {
  const { filters, setFilters } = usePropertyFiltersStore();

  const handleChange = (value: string | string[]) => {
    setFilters({ [`FIELD_${field.name}`]: value });
  };

  return (
    <div>
      <div className="text-[#5D5D5D] mb-2.5">{field.label}</div>
      <div className="flex gap-[15px] flex-wrap">
        {field.options?.map((option) => (
          <div
            key={option.value}
            className={classNames(
              'flex-center cursor-pointer rounded-[8px] min-w-[170px] h-[40px] font-bold',
              {
                'bg-[#f5f5f5] text-[#333]': !filters[
                  `FIELD_${field.name}`
                ]?.includes(option.value),
                'bg-[#006CFB] text-white': filters[
                  `FIELD_${field.name}`
                ]?.includes(option.value),
                'min-w-[50px]': option.label.length <= 5,
                'min-w-[40px]': option.label.length <= 3 || isSelect,
              },
            )}
            onClick={() => {
              if (multi) {
                if (filters[`FIELD_${field.name}`]?.includes(option.value))
                  handleChange(
                    filters[`FIELD_${field.name}`].filter(
                      (v: string) => v !== option.value,
                    ),
                  );
                else {
                  const currentFieldValue =
                    filters[`FIELD_${field.name}`] || [];
                  handleChange([...currentFieldValue, option.value]);
                }
              } else handleChange(option.value);
            }}
          >
            {option.value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioField;
