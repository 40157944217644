import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import usePropertyFiltersStore from '../stores/usePropertyFiltersStore';

interface IField {
  name: string;
  label: string;
}

interface ICounterFieldProps {
  field: IField;
}

const CounterField: React.FC<ICounterFieldProps> = ({ field }) => {
  const { t } = useTranslation();

  const { filters, setFilters } = usePropertyFiltersStore();

  const handleFromChange = (value: number) => {
    setFilters({ [`FIELD_${field.name}From`]: value });
  };

  const handleToChange = (value: number) => {
    setFilters({ [`FIELD_${field.name}To`]: value });
  };

  return (
    <div className="grid grid-cols-[1fr_auto] items-center gap-[20px]">
      <div className="text-[#5D5D5D]">{field.label}</div>
      <div className="flex items-center gap-6">
        <div className="flex items-center gap-2.5">
          <div className="text-[12px] text-[#AAAAAA80] font-medium">
            {t('placeholder-from')}
          </div>
          <div className="relative w-[100px]">
            <div
              className={classNames(
                'absolute top-[12px] left-[12px] font-Gilroy text-[18px] font-semibold select-none',
                {
                  'text-[#006CFB] cursor-pointer':
                    +filters[`FIELD_${field.name}From`] > 0,
                  'text-[#ccc]':
                    +filters[`FIELD_${field.name}From`] === 0 ||
                    filters[`FIELD_${field.name}From`] === undefined,
                },
              )}
              onClick={() => {
                const oldValue = filters[`FIELD_${field.name}From`] || 0;
                const newValue = +oldValue > 0 ? +oldValue - 1 : 0;
                handleFromChange(newValue);
              }}
            >
              -
            </div>
            <input
              className="w-full text-sm rounded-[15px] px-[16px] py-[15px] border outline-none border-[#e2e8f0] text-center"
              value={filters[`FIELD_${field.name}From`] || 0}
              onChange={(e) => handleFromChange(Number(e.target.value))}
              placeholder={t('placeholder-from')}
            />
            <div
              className="absolute top-[12px] right-[12px] font-Gilroy text-[18px] text-[#006CFB] cursor-pointer select-none"
              onClick={() => {
                const oldValue = filters[`FIELD_${field.name}From`] || 0;
                const newValue = +oldValue >= 0 ? +oldValue + 1 : 0;
                handleFromChange(newValue);
              }}
            >
              +
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2.5">
          <div className="text-[12px] text-[#AAAAAA80] font-medium">
            {t('placeholder-to')}
          </div>
          <div className="relative w-[100px]">
            <div
              className={classNames(
                'absolute top-[12px] left-[12px] font-Gilroy text-[18px] font-semibold select-none',
                {
                  'text-[#006CFB] cursor-pointer':
                    +filters[`FIELD_${field.name}To`] > 0,
                  'text-[#ccc]':
                    +filters[`FIELD_${field.name}To`] === 0 ||
                    filters[`FIELD_${field.name}To`] === undefined,
                },
              )}
              onClick={() => {
                const oldValue = filters[`FIELD_${field.name}To`] || 0;
                const newValue = +oldValue > 0 ? +oldValue - 1 : 0;
                handleToChange(newValue);
              }}
            >
              -
            </div>
            <input
              className="w-full text-sm rounded-[15px] px-[16px] py-[15px] border outline-none border-[#e2e8f0] text-center"
              value={filters[`FIELD_${field.name}To`] || 0}
              onChange={(e) => handleToChange(Number(e.target.value))}
              placeholder={t('placeholder-to')}
            />
            <div
              className="absolute top-[12px] right-[12px] font-Gilroy text-[18px] text-[#006CFB] cursor-pointer select-none"
              onClick={() => {
                const oldValue = filters[`FIELD_${field.name}To`] || 0;
                const newValue = +oldValue >= 0 ? +oldValue + 1 : 0;
                handleToChange(newValue);
              }}
            >
              +
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterField;
