import { FC } from 'react';

import { ISvgProps } from '../../../types/media';

const PriceIcon: FC<ISvgProps> = ({ height, width, color, opacity }) => {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill={color || '#333333'}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M10.0002 10.417C9.22661 10.417 8.48475 10.7243 7.93777 11.2713C7.39079 11.8182 7.0835 12.5601 7.0835 13.3337C7.0835 14.1072 7.39079 14.8491 7.93777 15.3961C8.48475 15.943 9.22661 16.2503 10.0002 16.2503C10.7737 16.2503 11.5156 15.943 12.0626 15.3961C12.6095 14.8491 12.9168 14.1072 12.9168 13.3337C12.9168 12.5601 12.6095 11.8182 12.0626 11.2713C11.5156 10.7243 10.7737 10.417 10.0002 10.417ZM8.75016 13.3337C8.75016 13.0021 8.88186 12.6842 9.11628 12.4498C9.3507 12.2154 9.66864 12.0837 10.0002 12.0837C10.3317 12.0837 10.6496 12.2154 10.884 12.4498C11.1185 12.6842 11.2502 13.0021 11.2502 13.3337C11.2502 13.6652 11.1185 13.9831 10.884 14.2175C10.6496 14.452 10.3317 14.5837 10.0002 14.5837C9.66864 14.5837 9.3507 14.452 9.11628 14.2175C8.88186 13.9831 8.75016 13.6652 8.75016 13.3337Z" />
        <path d="M14.605 4.26299L11.9558 0.548828L2.215 8.33049L1.675 8.32466V8.33299H1.25V18.333H18.75V8.33299H17.9483L16.3533 3.66716L14.605 4.26299ZM16.1875 8.33299H7.83083L14.055 6.21133L15.3233 5.80549L16.1875 8.33299ZM12.9583 4.82466L6.53333 7.01466L11.6217 2.94966L12.9583 4.82466ZM2.91667 15.1405V11.5238C3.26825 11.3992 3.58759 11.1978 3.85141 10.9341C4.11524 10.6704 4.31689 10.3512 4.44167 9.99966H15.5583C15.6831 10.3513 15.8847 10.6707 16.1485 10.9345C16.4123 11.1983 16.7317 11.3999 17.0833 11.5247V15.1413C16.7317 15.2661 16.4123 15.4677 16.1485 15.7315C15.8847 15.9953 15.6831 16.3147 15.5583 16.6663H4.44333C4.31849 16.3144 4.11664 15.9948 3.85252 15.7308C3.5884 15.4668 3.26867 15.2651 2.91667 15.1405Z" />
      </g>
    </svg>
  );
};

export default PriceIcon;
