import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { GoogleMapsProvider } from './api/google/GoogleMapsProvider';

import Router from './router/router';

import GlobalLoader from './common/components/GlobalLoader/GlobalLoader';
import Notification from './common/components/Notification/Notification';
import Modal from './common/components/Modal/Modal';

import './App.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleMapsProvider>
        <>
          <GlobalLoader />
          <Router />
          <Notification />
          <Modal />
        </>
      </GoogleMapsProvider>
    </QueryClientProvider>
  );
};

export default App;
