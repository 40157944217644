import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Tabs, { ITab } from '../../common/components/Tabs/Tabs';
import ImageWithFallback from '../../common/components/Image/ImageWithFallback';

import LeadButtons from './LeadButtons';

import SlideImageOne from '../../common/assets/images/agencies/agenciesSlideImageOne.png';
import SlideImageTwo from '../../common/assets/images/agencies/agenciesSlideImageTwo.png';
import SlideImageOneMob from '../../common/assets/images/agencies/agenciesSlideImageOne_mob.png';
import SlideImageTwoMob from '../../common/assets/images/agencies/agenciesSlideImageTwo_mob.png';

const Product = ({
  amount,
  cost,
  price,
}: {
  amount: string;
  cost: string;
  price: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-between w-[130px] h-[130px] xl:w-[146px] 2xl:w-[180px] xl:h-[146px] bg-primary text-white py-[20px] px-[12px] rounded-[20px]">
      <div>
        <div className="text-body-small mb-[5px]">
          <span className="lg:text-body-default font-semibold">{amount}</span>{' '}
          <span>{t('listings')}</span>
        </div>
        <div className="text-[10px] lg:text-[12px]">
          {cost} Kč/{t('listing')}
        </div>
      </div>
      <div className="text-body-small lg:text-body-default font-semibold">
        {price} Kč
      </div>
    </div>
  );
};

const Slide = ({
  id,
  title,
  description,
  content,
}: {
  id: number;
  title: ReactNode;
  description: ReactNode;
  content: ReactNode;
}) => (
  <div className="grid lg:grid-cols-[300px_1fr] gap-[5px] xl:grid-cols-[550px_1fr] items-center lg:h-[449px] px-[15px] lg:px-[25px] bg-[#F1F4FE] rounded-[20px]">
    <div className="order-2 lg:order-1 flex flex-col justify-end h-full py-[15px] lg:py-[40px]">
      <div className="hidden lg:flex items-center flex-1 px-[15px] text-[88px] text-primary leading-[120px] font-bold">
        {id}
      </div>
      <div>
        <div className="text-heading-4 lg:text-heading-3 text-black mb-[15px]">
          {title}
        </div>
        <div className="text-body-small lg:text-body-default">
          {description}
        </div>
      </div>
    </div>
    <div className="order-1 lg:order-2 w-full">{content}</div>
  </div>
);

const AgenciesTabs: React.FC = () => {
  const { t } = useTranslation();

  const tabOptions: ITab[] = [
    {
      id: 1,
      label: t('mobile_app'),
      content: (
        <Slide
          id={1}
          title={t('mobile_app')}
          description={
            <>
              {t('mobile_app_description')}{' '}
              <a className="text-accent font-bold" href="/">
                {t('app_store')}.
              </a>
            </>
          }
          content={
            <div className="flex-center">
              <div className="flex-center flex-1 lg:hidden pt-[15px]">
                <ImageWithFallback
                  width={322}
                  height={354}
                  src={SlideImageOneMob}
                />
              </div>
              <div className="hidden lg:flex-center">
                <ImageWithFallback
                  width={440}
                  height={450}
                  src={SlideImageOne}
                />
              </div>
            </div>
          }
        />
      ),
    },
    {
      id: 2,
      label: t('in_platform_chat'),
      content: (
        <Slide
          id={2}
          title={t('in_platform_chat')}
          description={t('in_platform_chat_description')}
          content={
            <div className="flex-center">
              <div className="flex-center flex-1 lg:hidden pt-[15px]">
                <ImageWithFallback
                  width={322}
                  height={374}
                  src={SlideImageTwoMob}
                />
              </div>
              <div className="hidden lg:flex-center">
                <ImageWithFallback
                  width={760}
                  height={450}
                  src={SlideImageTwo}
                />
              </div>
            </div>
          }
        />
      ),
    },
    {
      id: 3,
      label: t('affordable_pricing_plans'),
      content: (
        <Slide
          id={3}
          title={t('affordable_pricing_plans')}
          description={t('affordable_pricing_plans_description')}
          content={
            <div className="lg:h-[449px] py-[20px] md:py-[40px]">
              <div className="flex md:hidden justify-around gap-[30px] items-center px-[10px] mb-[34px]">
                <div>
                  <Product amount="5" cost="440" price="2 200" />
                </div>
                <div>
                  <Product amount="10" cost="135" price="2 500" />
                </div>
              </div>
              <div className="flex md:hidden justify-around gap-[30px] items-center px-[10px] mb-[34px]">
                <div>
                  <Product amount="50" cost="84" price="4 200" />
                </div>
                <div>
                  <Product amount="100" cost="70" price="7 000" />
                </div>
              </div>
              <div className="hidden md:flex justify-around lg:justify-end gap-[11px] mb-[42px]">
                <div className="pt-[156px]">
                  <Product amount="5" cost="440" price="2 200" />
                </div>
                <div className="pt-[104px]">
                  <Product amount="10" cost="135" price="2 500" />
                </div>
                <div className="pt-[52px]">
                  <Product amount="50" cost="84" price="4 200" />
                </div>
                <div>
                  <Product amount="100" cost="70" price="7 000" />
                </div>
              </div>
              <div className="text-[12px] sm:text-body-default pl-[10px]">
                {t('more_details_about')}{' '}
                <a className="text-accent" href="https://agent.reelty.cz">
                  {t('agents_account_dashboard')}
                </a>
              </div>
            </div>
          }
        />
      ),
    },
  ];

  return (
    <div>
      <div className="mb-[25px]">
        <Tabs options={tabOptions} />
      </div>
      <div className="lg:flex-center">
        <LeadButtons />
      </div>
    </div>
  );
};

export default AgenciesTabs;
