import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { postPropertyComplaint } from '../../../../api/services/property/requests';

import useGlobalLoaderStore from '../../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../../common/stores/useGlobalErrorStore';
import useGlobalModalStore from '../../../../common/stores/useGlobalModalStore';

import Textarea from '../../../../common/components/Textarea/Textarea';

interface IComplaintModalProps {
  id: string;
}

interface IFormValues {
  complaintReason: string;
}

const ComplaintModal: React.FC<IComplaintModalProps> = ({ id }) => {
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const defaultValues: IFormValues = {
    complaintReason: '',
  };

  const { control, handleSubmit, watch } = useForm<IFormValues>({
    mode: 'onBlur',
    defaultValues,
  });

  const complaintReason = watch('complaintReason');

  const onSubmit = async (value: IFormValues) => {
    const payload = {
      message: value.complaintReason,
    };

    try {
      setLoader(true);
      await postPropertyComplaint(id, payload);
      onClose();
    } catch (error: any) {
      setError(error.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid gap-[20px] w-[408px] p-[25px]"
    >
      <div className="text-[14px] text-[#5D5D5D]">
        {t('complaint_modal_text')}
      </div>
      <div>
        <Textarea
          name="complaintReason"
          control={control}
          className="h-[260px] text-[16px] text-[#5D5D5D] px-[10px] py-[15px]"
          placeholder="I think this advert is inappropriate because...."
        />
      </div>
      <div>
        <button
          className="flex-center w-full h-[40px] rounded-[8px] text-[16px] text-[#ffffff] font-semibold bg-[#006CFB] disabled:bg-[#CCCCCC]"
          disabled={!complaintReason?.length}
          type="submit"
        >
          {t('send_complaint')}
        </button>
      </div>
    </form>
  );
};

export default ComplaintModal;
