import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ICategorySchemaGroup } from '../types/dto/category';
import { IPropertyField } from '../types/dto/property';

const usePropertyFieldsFromSchema = (
  fields?: IPropertyField[],
  schema?: ICategorySchemaGroup,
) => {
  const { lang } = useParams();

  return useMemo(() => {
    if (!fields || !schema) return null;
    const schemaFieldIds = schema.fields.map((f) => f.id);
    const fieldsInSchema = fields.filter((f) =>
      schemaFieldIds.includes(f.field.id),
    );

    return fieldsInSchema.map((f) => {
      switch (f.field.type) {
        case 'TAG_SELECT': {
          return {
            label: f.field.label,
            value: f.tag?.label?.[lang as keyof typeof f.tag.label],
            unit: f.field.meta?.unit,
          };
        }
        case 'TAG_MULTI_SELECT': {
          return {
            label: f.field.label,
            value: f.tags
              .filter((t) => !!t.label?.[lang as keyof typeof t.label])
              .map((t) => t.label?.[lang as keyof typeof t.label])
              .join(', '),
            unit: f.field.meta?.unit,
          };
        }
        default:
          return {
            label: f.field.label,
            value: f.value,
            unit: f.field.meta?.unit,
          };
      }
    });
  }, [fields, lang, schema]);
};

export default usePropertyFieldsFromSchema;
