import { useTranslation } from 'react-i18next';

import usePropertySearchStore from '../../stores/usePropertySearchStore';

import DropdownSimple from '../Dropdown/DropdownSimple';

import CheckIcon from '../../assets/icons/CheckIcon';

const TitleComponent = ({ title = '' }) => {
  return (
    <div className="flex-center h-[40px] px-[15px] bg-[#fff] border border-[#006CFB] rounded-[8px] text-[12px] text-[#11142D] font-semibold">
      {title}
    </div>
  );
};

const PropertyRoomsSelector = ({ options = [], isBedRooms = false }) => {
  const { t } = useTranslation();

  const { rooms, setRooms, bedRooms, setBedRooms } = usePropertySearchStore();

  const currentType = isBedRooms ? bedRooms : rooms;
  const setCurrentType = isBedRooms ? setBedRooms : setRooms;

  const onChange = (value: string) => {
    const newRooms = currentType.includes(value)
      ? currentType.filter((room) => room !== value)
      : [...currentType, value];

    setCurrentType(newRooms);
  };

  const optionsMap = options.map((option) => {
    const { label, id } = option;
    const isActive = currentType.includes(id);

    return (
      <div
        key={id}
        onClick={() => onChange(id)}
        className="flex items-center justify-between gap-[15px] w-full cursor-pointer"
      >
        <div className="text-[12px] whitespace-nowrap text-[#5D5D5D]">
          {label}
        </div>
        <div className="w-[10px] h-[10px]">
          <div className="relative flex-center w-4 h-4">
            <input
              type="checkbox"
              className="appearance-none w-4 h-4 border border-[#006CFB] rounded-[2px] bg-transparent shrink-0 cursor-pointer checked:rounded-[4px] checked:bg-[#006CFB]"
              checked={isActive}
              readOnly
            />
            <CheckIcon
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
              height={10}
              width={10}
              color="white"
              strokeWidth={2.5}
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <DropdownSimple
      menuClass="border-0 shadow"
      title={<TitleComponent title={isBedRooms ? t('bedRooms') : t('rooms')} />}
      maxHeight={1900}
    >
      <div className="flex flex-col gap-[15px] py-2.5 px-5">{optionsMap}</div>
    </DropdownSimple>
  );
};

export default PropertyRoomsSelector;
