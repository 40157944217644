import { create } from 'zustand';

interface ICoordinates {
  lat: number;
  lng: number;
  radius?: number;
}

interface IAddress {
  formattedAddress: string;
  city?: string;
}

interface IRange {
  from: string;
  to: string;
}

interface IPropertyTypes {
  path: string[];
  entities: any[];
}

interface IFilters {
  [key: string]: any;
}

interface IDefaultValues {
  propertyTypes: IPropertyTypes;
  area: IRange;
  price: IRange;
  address: IAddress;
  osmId: string[];
  coordinates: ICoordinates;
  sort: string;
  rooms: string[];
  bedRooms: string[];
  filters: IFilters;
}

interface IPropertySearchStore extends IDefaultValues {
  setPropertyTypes: (propertyTypes: IPropertyTypes) => void;
  setArea: (area: IRange) => void;
  setPrice: (price: IRange) => void;
  setAddress: (address: IAddress) => void;
  setOsmId: (osmId: string[]) => void;
  setCoordinates: (coordinates: ICoordinates) => void;
  setSort: (sort: string) => void;
  setRooms: (rooms: string[]) => void;
  setBedRooms: (bedRooms: string[]) => void;
  setFilters: (filters: IFilters) => void;
  reset: () => void;
}

const defaultValues: IDefaultValues = {
  propertyTypes: { path: [], entities: [] },
  area: { from: '', to: '' },
  price: { from: '', to: '' },
  address: { formattedAddress: '' },
  osmId: [],
  coordinates: {
    lat: 50.0755,
    lng: 14.4378,
  },
  sort: 'default',
  rooms: [],
  bedRooms: [],
  filters: {},
};

const usePropertySearchStore = create<IPropertySearchStore>((set) => ({
  ...defaultValues,
  setPropertyTypes: (propertyTypes) => set({ propertyTypes }),
  setArea: (area) => set({ area }),
  setPrice: (price) => set({ price }),
  setAddress: (address) => set({ address }),
  setOsmId: (osmId) => set({ osmId }),
  setCoordinates: (coordinates) => set({ coordinates }),
  setSort: (sort) => set({ sort }),
  setRooms: (rooms) => set({ rooms }),
  setBedRooms: (bedRooms) => set({ bedRooms }),
  setFilters: (filters) => set({ filters }),
  reset: () => set(defaultValues),
}));

export default usePropertySearchStore;
