import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getCategorySchemaById } from '../../../api/services/category/requests';

import useGlobalLoaderStore from '../../stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../stores/useGlobalErrorStore';
import usePropertySearchStore from '../../stores/usePropertySearchStore';

import PropertyTypeSelector from './PropertyTypeSelector';
import PropertyRoomsSelector from './PropertyRoomsSelector';
import PropertyRangeSelector from './PropertyRangeSelector';
import PropertyFilters from './PropertyFilters';
import PropertyAddressInput from './PropertyAddressInput';

interface IPropertySearchBarProps {
  className?: string;
}

const PropertySearchBar: React.FC<IPropertySearchBarProps> = ({
  className = '',
}) => {
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { propertyTypes, area, setArea, price, setPrice, setRooms } =
    usePropertySearchStore();

  const id = propertyTypes.entities[0]?.id;

  const { data: schema = {} as any } = useQuery(
    ['category_schema', id],
    async () => {
      setLoader(true);

      const data = await getCategorySchemaById(id);
      setRooms([]);
      setLoader(false);
      return data;
    },
    {
      enabled: !!id,
      onError: (error: any) => {
        setError(error.response?.data?.message);
        setLoader(false);
      },
    },
  );

  const entityName = propertyTypes.entities[0]?.name || '';

  const roomsOptions =
    schema.groups
      ?.find((g: { name: string }) => g.name === 'parameters')
      ?.fields?.find((f: { name: string }) => f.name === 'roomsCountFlat')
      ?.tags?.sort((o1: any, o2: any) => o1.order - o2.order) || [];
  const showRooms = roomsOptions.length > 0 && entityName.includes('apartment');

  const bedRoomsOptions =
    schema.groups
      ?.find((g: { name: string }) => g.name === 'parameters')
      ?.fields?.find((f: { name: string }) => f.name === 'roomsCountHouse')
      ?.tags.sort((o1: any, o2: any) => o1.order - o2.order) || [];
  const showBedrooms =
    bedRoomsOptions.length > 0 && entityName.includes('house');

  const showPrice =
    entityName.includes('commercial') ||
    entityName.includes('apartment') ||
    entityName.includes('house') ||
    entityName.includes('room') ||
    entityName.includes('garage');

  const showArea = entityName.includes('commercial');

  return (
    <div
      className={`flex items-center gap-4 w-full h-[40px] bg-white ${className}`}
    >
      <PropertyTypeSelector />
      {showRooms && <PropertyRoomsSelector options={roomsOptions} />}
      {showBedrooms && (
        <PropertyRoomsSelector options={bedRoomsOptions} isBedRooms />
      )}
      {showPrice && (
        <PropertyRangeSelector
          placeholder={t('placeholder-price')}
          unit="Kč"
          defaultValue={price}
          onChange={setPrice}
        />
      )}
      {showArea && (
        <PropertyRangeSelector
          placeholder={t('placeholder-area')}
          unit="m²"
          defaultValue={area}
          onChange={setArea}
        />
      )}
      <PropertyFilters groups={schema.groups} />
      <PropertyAddressInput />
    </div>
  );
};

export default PropertySearchBar;
