import React, { useState } from 'react';

import AchtungIcon from '../../../common/assets/icons/AchtungIcon';

const ImageWithFallback: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement>
> = ({ ...props }) => {
  const [isError, setIsError] = useState(false);

  return isError ? (
    <div className={`flex-center w-full h-full ${props.className}`}>
      <AchtungIcon
        width={100}
        height={100}
        className="max-w-full max-h-full object-contain text-yellow-400"
      />
    </div>
  ) : (
    <img
      onError={() => setIsError(true)}
      style={{ display: isError ? 'none' : 'block' }}
      {...props}
    />
  );
};

export default ImageWithFallback;
