import { useMemo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getSubTree } from '../../utils/helpers';

import usePropertySearchStore from '../../stores/usePropertySearchStore';

import usePropertyCategories, {
  IConvertedCategory,
} from '../../hooks/usePropertyCategories';

import PropertyTypeSelectorItem from './PropertyTypeSelectorItem';

const PropertyTypeSelector = () => {
  const { type } = useParams();
  const { t } = useTranslation();

  const { propertyTypes, setPropertyTypes } = usePropertySearchStore();

  const { categories } = usePropertyCategories();

  const handleRadioChange = useCallback(
    (level: number, selectedValue: string, path = [...propertyTypes.path]) => {
      path[level] = selectedValue;
      path.splice(level + 1);

      const currentSubTree = getSubTree(categories, path);

      const subTreeChildren = currentSubTree?.children;
      if (subTreeChildren?.length) {
        const firstChildKey = subTreeChildren[0].name;

        if (firstChildKey) {
          path.push(firstChildKey);
          handleRadioChange(level + 1, firstChildKey, path);
        }
      }

      const newSubTree = getSubTree(categories, path);

      const finalEntities = newSubTree?.entities || [];

      const newValue = {
        path: path,
        entities: finalEntities.length > 0 ? [finalEntities[0]] : [],
      };

      setPropertyTypes(newValue);
    },
    [propertyTypes, categories, setPropertyTypes],
  );

  const handleCheckboxChange = useCallback(
    (entity: any) => {
      if (
        propertyTypes.entities.find((ent) => ent.id === entity.id) &&
        propertyTypes.entities.length === 1
      )
        return;

      const updatedEntities = [entity];
      const updatedValue = { ...propertyTypes, entities: updatedEntities };

      setPropertyTypes(updatedValue);
    },
    [propertyTypes, setPropertyTypes],
  );

  const renderTree = useCallback(
    (treeArr?: IConvertedCategory[], level = 0) => {
      if (!treeArr) return null;
      const currentSelection = propertyTypes.path[level] || null;
      const currentObject = treeArr.find(
        (item) => item.name === currentSelection,
      );

      const title = currentObject?.title;

      const options = treeArr.map((obj) => ({
        id: obj.id,
        title: obj.title,
        active: currentSelection === obj.name,
        onChange: () => {
          if (currentSelection === obj.name) return;

          handleRadioChange(level, obj.name);
        },
      }));

      const entities = currentObject?.entities?.map((entity) => ({
        id: entity.id,
        title: entity.title,
        active: !!propertyTypes.entities.find((ent) => ent.id === entity.id),
        onChange: () => {
          handleCheckboxChange(entity);
        },
      }));

      const entityTitle = entities?.find((entity) => entity.active)?.title;

      return (
        <div className="flex items-center gap-4">
          <PropertyTypeSelectorItem
            title={`${level === 0 ? `${t('for')} ` : ''}${title}`}
            options={options}
          />

          {!!currentObject && renderTree(currentObject.children, level + 1)}

          {!!currentObject?.entities?.length && (
            <PropertyTypeSelectorItem
              title={entityTitle}
              options={entities}
              isEntities
            />
          )}
        </div>
      );
    },
    [
      propertyTypes.path,
      propertyTypes.entities,
      t,
      handleRadioChange,
      handleCheckboxChange,
    ],
  );

  const renderedTree = useMemo(
    () => renderTree(categories),
    [categories, renderTree],
  );

  useEffect(() => {
    if (!propertyTypes.path.length && categories.length) {
      const autoSelectFirstFromTree = (
        tree: IConvertedCategory | null,
        param: keyof IConvertedCategory,
      ) => {
        const path: string[] = [];
        let currentTree = tree;
        while (currentTree) {
          const firstKey = currentTree[param];
          path.push(firstKey as string);
          currentTree = currentTree.children ? currentTree.children[0] : null;
        }
        return path;
      };
      const firstTree = categories[type === 'rent' ? 0 : 1];

      const path =
        type === 'rent'
          ? ['rent', 'longTerm', 'residental']
          : autoSelectFirstFromTree(firstTree, 'name');

      const currentSubTree = getSubTree(categories, path);

      const finalEntities = currentSubTree?.entities || [];

      const updatedValue = {
        path,
        entities: finalEntities.length > 0 ? [finalEntities[0]] : [],
      };

      if (JSON.stringify(propertyTypes.path) !== JSON.stringify(path)) {
        setPropertyTypes(updatedValue);
      }
    }
  }, [categories, type, propertyTypes, setPropertyTypes]);

  return <div className="flex items-center gap-4">{renderedTree}</div>;
};

export default PropertyTypeSelector;
