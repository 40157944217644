import { useMemo } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import routesUnauthorized from '../../router/routes';

const routerUnauthorized = createBrowserRouter(routesUnauthorized);

const useRouter = () => {
  const router = useMemo(() => routerUnauthorized, []);

  return {
    router,
  };
};

export default useRouter;
