import { IPagination } from '../../../common/types/response';

export interface IGetPropertySearchPayload {
  categories?: string[];
  city?: { [key: string]: any }[];
  country?: string;
  district?: { [key: string]: any }[];
  houseNumber?: string;
  limit?: number;
  locationPoint?: string;
  locationRadius?: number;
  osmIds?: string[];
  page?: number;
  postalCode?: string;
  priceFrom?: number;
  priceTo?: number;
  priceType?: EPriceType;
  region?: { [key: string]: any }[];
  sortField?: ESortField;
  sortOrder?: ESortOrder;
  street?: string;
  [property: string]: any;
}

export interface IGetPropertySearchFullResponse<T> extends IPagination<T> {
  polygon?: any;
}

export interface IPostPropertyComplaintPayload {
  message: string;
  [property: string]: any;
}

export interface IPostPropertyComplaintResponse {
  createdAt: Date;
  id: string;
  message: string;
  [property: string]: any;
}

export enum EPriceType {
  Full = 'full',
  PerDay = 'perDay',
  PerMonth = 'perMonth',
}

export enum ESortField {
  CreatedAt = 'createdAt',
  Price = 'price',
  TotalArea = 'totalArea',
}

export enum ESortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}
