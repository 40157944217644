import { create } from 'zustand';

const defaultValues = {
  filters: {} as any,
};

const usePropertyFiltersStore = create<any>((set) => ({
  ...defaultValues,
  setFilters: (newFilters: any) =>
    set((prevFilters: any) => {
      const updatedFilters = {
        ...prevFilters.filters,
        ...newFilters,
      };

      Object.keys(updatedFilters).forEach((key) => {
        const value = updatedFilters[key];
        if (
          value === '' ||
          (Array.isArray(value) && value.length === 0) ||
          value === undefined
        ) {
          delete updatedFilters[key];
        }
      });

      return { filters: updatedFilters };
    }),
  // setFilters: (newFilters) => set((prevFilters) => ({
  //   filters: {
  //     ...prevFilters.filters,
  //     ...newFilters,
  //   },
  // })),
  reset: () => set(defaultValues),
}));

export default usePropertyFiltersStore;
