import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const ExtLinkIcon: FC<ISvgProps> = ({
  height = 20,
  width = 20,
  color = 'black',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3206 1.99344C17.2958 1.99423 17.271 1.9964 17.2464 1.99995H13.3337C13.2453 1.9987 13.1576 2.01502 13.0756 2.04797C12.9937 2.08091 12.919 2.12982 12.8561 2.19185C12.7932 2.25388 12.7433 2.32779 12.7092 2.4093C12.6751 2.4908 12.6575 2.57827 12.6575 2.66662C12.6575 2.75497 12.6751 2.84244 12.7092 2.92394C12.7433 3.00545 12.7932 3.07936 12.8561 3.14139C12.919 3.20342 12.9937 3.25233 13.0756 3.28527C13.1576 3.31821 13.2453 3.33454 13.3337 3.33329H15.7243L8.86231 10.1953C8.79832 10.2567 8.74724 10.3303 8.71205 10.4117C8.67686 10.4931 8.65827 10.5807 8.65737 10.6694C8.65646 10.7581 8.67327 10.8461 8.70679 10.9282C8.74032 11.0103 8.78989 11.0849 8.85261 11.1477C8.91533 11.2104 8.98994 11.26 9.07205 11.2935C9.15417 11.327 9.24216 11.3438 9.33085 11.3429C9.41954 11.342 9.50717 11.3234 9.58858 11.2882C9.67 11.253 9.74358 11.202 9.80501 11.138L16.667 4.27599V6.66662C16.6657 6.75496 16.6821 6.84267 16.715 6.92465C16.748 7.00663 16.7969 7.08124 16.8589 7.14415C16.9209 7.20707 16.9948 7.25702 17.0763 7.29112C17.1578 7.32522 17.2453 7.34278 17.3337 7.34278C17.422 7.34278 17.5095 7.32522 17.591 7.29112C17.6725 7.25702 17.7464 7.20707 17.8084 7.14415C17.8705 7.08124 17.9194 7.00663 17.9523 6.92465C17.9853 6.84267 18.0016 6.75496 18.0003 6.66662V2.75125C18.0136 2.65527 18.0058 2.55755 17.9775 2.46489C17.9491 2.37223 17.9009 2.28686 17.8362 2.21473C17.7715 2.14259 17.6919 2.08543 17.6028 2.04721C17.5138 2.00899 17.4175 1.99065 17.3206 1.99344ZM4.00033 4.66662C3.27162 4.66662 2.66699 5.27124 2.66699 5.99995V16C2.66699 16.7287 3.27162 17.3333 4.00033 17.3333H14.0003C14.729 17.3333 15.3337 16.7287 15.3337 16V9.33329V7.61454L14.0003 8.94787V10.6666V16H4.00033V5.99995H9.33366H10.667H11.0524L12.3857 4.66662H10.667H9.33366H4.00033Z"
        fill={color}
      />
    </svg>
  );
};

export default ExtLinkIcon;
