import React from 'react';
import { RouterProvider } from 'react-router-dom';

import useRouter from '../common/hooks/useRouter';

const Router = () => {
  const { router } = useRouter();

  if (!router) return null;

  return <RouterProvider router={router} />;
};

export default Router;
