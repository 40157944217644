import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const CheckIcon: FC<ISvgProps> = ({
  height = 25,
  width = 24,
  color = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5493_16145)">
        <path
          d="M11.0068 3.24816L10.9998 18.6862C10.9998 18.9514 11.1052 19.2057 11.2927 19.3933C11.4802 19.5808 11.7346 19.6862 11.9998 19.6862C12.265 19.6862 12.5194 19.5808 12.7069 19.3933C12.8945 19.2057 12.9998 18.9514 12.9998 18.6862L13.0068 3.26516L15.9188 6.17816C16.1063 6.36563 16.3606 6.47095 16.6258 6.47095C16.891 6.47095 17.1453 6.36563 17.3328 6.17816C17.5203 5.99063 17.6256 5.73632 17.6256 5.47116C17.6256 5.206 17.5203 4.95169 17.3328 4.76416L14.1218 1.54916C13.8432 1.27038 13.5124 1.04923 13.1483 0.898345C12.7842 0.747461 12.3939 0.6698 11.9998 0.6698C11.6057 0.6698 11.2154 0.747461 10.8513 0.898345C10.4872 1.04923 10.1564 1.27038 9.87781 1.54916L6.66681 4.76116C6.47934 4.94869 6.37402 5.20299 6.37402 5.46816C6.37402 5.73332 6.47934 5.98763 6.66681 6.17516C6.85434 6.36263 7.10865 6.46795 7.37381 6.46795C7.63897 6.46795 7.89328 6.36263 8.08081 6.17516L11.0068 3.24816Z"
          fill={color}
        />
        <path
          d="M22 17.6694V21.6694C22 21.9346 21.8946 22.189 21.7071 22.3765C21.5196 22.5641 21.2652 22.6694 21 22.6694H3C2.73478 22.6694 2.48043 22.5641 2.29289 22.3765C2.10536 22.189 2 21.9346 2 21.6694V17.6694C2 17.4042 1.89464 17.1499 1.70711 16.9623C1.51957 16.7748 1.26522 16.6694 1 16.6694C0.734784 16.6694 0.48043 16.7748 0.292893 16.9623C0.105357 17.1499 0 17.4042 0 17.6694L0 21.6694C0 22.4651 0.31607 23.2281 0.87868 23.7907C1.44129 24.3533 2.20435 24.6694 3 24.6694H21C21.7956 24.6694 22.5587 24.3533 23.1213 23.7907C23.6839 23.2281 24 22.4651 24 21.6694V17.6694C24 17.4042 23.8946 17.1499 23.7071 16.9623C23.5196 16.7748 23.2652 16.6694 23 16.6694C22.7348 16.6694 22.4804 16.7748 22.2929 16.9623C22.1054 17.1499 22 17.4042 22 17.6694Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5493_16145">
          <rect
            width={width}
            height={width}
            fill={color}
            transform="translate(0 0.670044)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckIcon;
