import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { postLead } from '../../api/services/lead/requests';

import useGlobalErrorStore from '../../common/stores/useGlobalErrorStore';

interface LeadButtonsProps {
  variant?: 'primary' | 'secondary';
  gap?: 'default' | 'diagram';
}

const LeadButtons: React.FC<LeadButtonsProps> = ({
  variant = 'primary',
  gap = 'default',
}) => {
  const [value, setValue] = useState('');
  const [isContact, setIsContact] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const { lang } = useParams();
  const { t } = useTranslation();

  const { setError } = useGlobalErrorStore();
  const handleSignup = () => {
    window.location.href = 'https://agent.reelty.cz/create-account';
  };

  const handleLead = async () => {
    const payload = {
      page: `/${lang}/agencies`,
      email: value,
    };

    try {
      await postLead(payload);
      setIsSent(true);
      setIsContact(false);
    } catch (error: any) {
      setError(error.response?.data?.message);
    }
  };

  return (
    <div
      className={`flex flex-col md:flex-row md:items-center  ${gap === 'diagram' ? 'gap-[15px] md:gap-[70px]' : 'gap-[15px] md:gap-[25px]'}`}
    >
      <div>
        <button
          onClick={handleSignup}
          className={`flex-center   ${gap === 'diagram' ? 'h-[49px] md:h-[60px] w-[290px]' : 'h-[60px] w-full'} md:w-[260px] rounded-[20px] text-[18px]  font-bold ${variant === 'secondary' ? 'bg-white text-primary' : 'bg-primary text-white'}`}
        >
          {t('sign_up')}
        </button>
      </div>
      <div
        className={`w-full transition-all duration-500 overflow-hidden rounded-[20px] ${isContact ? 'max-w-[680px]' : 'md:max-w-[260px]'}`}
      >
        {isContact ? (
          <div className="flex flex-col md:flex-row md:items-center md:justify-end gap-[15px]">
            <div className="flex-1">
              <input
                className={`w-full md:max-w-[518px] ${gap === 'diagram' ? 'h-[49px] md:h-[60px]' : 'h-[60px]'} bg-white rounded-[20px] text-[18px] placeholder-[#C2C2C2] px-[25px] outline-none ${variant === 'secondary' ? '' : 'border border-primary'}`}
                type="email"
                value={value}
                onChange={(event) => setValue(event.target.value)}
                placeholder={t('placeholder_enter_email_address')}
              />
            </div>
            <div>
              <button
                onClick={handleLead}
                className={`flex-center w-full md:w-[147px] ${gap === 'diagram' ? 'h-[49px] md:h-[60px]' : 'h-[60px]'} rounded-[20px] text-[18px] font-bold ${variant === 'secondary' ? 'bg-white text-primary' : 'bg-primary text-white'}`}
              >
                {t('send')}
              </button>
            </div>
          </div>
        ) : isSent ? (
          <button
            className={`flex-center w-full md:w-[260px] ${gap === 'diagram' ? 'h-[49px] md:h-[60px]' : 'h-[60px]'} rounded-[20px] text-[18px] text-primary font-bold ${variant === 'secondary' ? 'bg-white' : 'bg-[#EAEFFE]'}`}
          >
            {t('thank_you')}
          </button>
        ) : (
          <button
            onClick={() => setIsContact(true)}
            className={`flex-center w-full md:w-[260px] ${gap === 'diagram' ? 'h-[49px] md:h-[60px]' : 'h-[60px]'} rounded-[20px] text-[18px] text-primary font-bold ${variant === 'secondary' ? 'bg-white' : 'bg-[#EAEFFE]'}`}
          >
            {t('contact_me')}
          </button>
        )}
      </div>
    </div>
  );
};

export default LeadButtons;
