import React from 'react';

import { IImage } from '../../common/types/media';

import PropertyCarousel from '../../common/components/PropertyCarousel/PropertyCarousel';

interface IPropertyPageCarouselProps {
  items: IImage[];
  data?: {
    property?: string;
    phone?: string;
  };
}

const PropertyPageCarousel: React.FC<IPropertyPageCarouselProps> = ({
  items,
  data = {},
}) => {
  const sliderSettings = {
    customPaging: (index: number) =>
      items ? (
        <div className="h-[70px] w-[100px]">
          <img
            src={items[index]?.url}
            width={50}
            height={50}
            alt={index.toString()}
            className="h-[70px] w-[100px] object-cover rounded-[4px]"
          />
        </div>
      ) : (
        <div />
      ),
    dots: true,
    dotsClass: '!flex gap-[10px] overflow-auto cursor-pointer',
  };

  return (
    <PropertyCarousel
      items={items}
      settings={sliderSettings}
      data={data}
      withFullScreen
      wrapperClass="aspect-[1.83] mb-3.5"
    />
  );
};

export default PropertyPageCarousel;
