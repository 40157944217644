import React from 'react';
import { Controller, Control } from 'react-hook-form';

interface ITextareaInputProps {
  control: Control<any>;
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
}

const TextareaInput: React.FC<ITextareaInputProps> = ({
  control,
  name,
  label,
  placeholder,
  className,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="grid">
          {label && (
            <label
              htmlFor={String(name)}
              className="text-sm text-[#333] mb-1.5"
            >
              {label}
            </label>
          )}
          <textarea
            value={field.value}
            name={String(name)}
            className={`h-[105px] rounded-[5px] outline-none text-[12px] resize-none border border-[#E2E8F0] ${className || ''}`}
            onChange={(event) => {
              field.onChange(event.target.value);
            }}
            placeholder={placeholder}
          />
        </div>
      )}
    />
  );
};

export default TextareaInput;
