import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import usePropertySearchStore from '../../common/stores/usePropertySearchStore';
import usePropertyStore from '../../common/stores/usePropertyStore';

import usePrevious from '../../common/hooks/usePrevious';
import usePropertySearch from '../../common/hooks/usePropertySearch';

import Container from '../../common/components/Container/Container';
import Pagination from '../../common/components/Pagination/Pagination';
import PropertySearchBar from '../../common/components/PropertySearchBarNew/PropertySearchBar';

import PropertySearchList from '../../features/search/components/PropertySearchList';

//todo: check translate after all done
const SearchPage = () => {
  const [resetKey, setResetKey] = useState(0);

  const navigate = useNavigate();
  const { lang, type } = useParams();

  const { propertyTypes, reset } = usePropertySearchStore();
  const { total, limit, page, setLimit, setPage } = usePropertyStore();

  usePropertySearch();

  const fixedType = type === 'rent' ? 'rent' : type === 'buy' ? 'sale' : null;
  const prevType = usePrevious(fixedType);

  const totalPages =
    (!!total && total > limit && Math.ceil(total / limit)) || 0;

  useEffect(() => {
    setLimit(15);
  }, [setLimit]);

  useEffect(() => {
    if (
      fixedType &&
      prevType !== fixedType &&
      propertyTypes.path[0] &&
      propertyTypes.path[0] !== fixedType
    ) {
      reset();
      setResetKey((prevKey) => prevKey + 1);
    } else if (
      fixedType &&
      propertyTypes.path[0] &&
      propertyTypes.path[0] !== fixedType
    ) {
      navigate(
        `/${lang}/search/${propertyTypes.path[0] === 'rent' ? 'rent' : 'buy'}`,
      );
    }
  }, [fixedType, lang, navigate, prevType, propertyTypes.path, reset]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-[36px] pb-7">
      <Container>
        <div className="mb-6 relative z-[999] fade-in" key={resetKey}>
          <PropertySearchBar />
        </div>
        <div className="mb-[50px] fade-in">
          <PropertySearchList />
        </div>
        <div className="flex-center">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={(page) => setPage(page)}
          />
        </div>
      </Container>
    </div>
  );
};

export default SearchPage;
