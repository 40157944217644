import { useEffect } from 'react';

const usePreventZoom = () => {
  useEffect(() => {
    const preventZoomKeys = (event: KeyboardEvent) => {
      if (event.ctrlKey && (event.key === '=' || event.key === '-')) {
        event.preventDefault();
      }
    };

    const preventZoomWheel = (event: WheelEvent) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', preventZoomKeys);
    window.addEventListener('wheel', preventZoomWheel, { passive: false });

    return () => {
      window.removeEventListener('keydown', preventZoomKeys);
      window.removeEventListener('wheel', preventZoomWheel);
    };
  }, []);
};

export default usePreventZoom;
