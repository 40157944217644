import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const MapIcon: FC<ISvgProps> = ({
  height = 20,
  width = 20,
  color = '#006CFB',
  strokeWidth = 1.5,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 4.54058V18.7956M13 1.18058V15.4356M1 6.59258C1 5.06558 1 4.30258 1.393 3.85758C1.532 3.69858 1.701 3.57258 1.89 3.48558C3.306 2.83258 5.162 4.55158 6.66 4.49858C6.85733 4.49125 7.053 4.46392 7.247 4.41658C9.431 3.88158 10.799 1.33658 13.045 1.02658C14.332 0.846582 15.745 1.62458 16.949 2.04058C17.939 2.38258 18.434 2.55358 18.717 2.96058C19 3.36758 19 3.90958 19 4.98958V13.4116C19 14.9376 19 15.7016 18.607 16.1466C18.469 16.3035 18.2997 16.4299 18.11 16.5176C16.694 17.1706 14.838 15.4526 13.34 15.5056C13.1421 15.5131 12.9455 15.5403 12.753 15.5866C10.569 16.1216 9.201 18.6666 6.955 18.9776C5.674 19.1556 2.108 18.2276 1.283 17.0426C1 16.6356 1 16.0956 1 15.0136V6.59258Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MapIcon;
