import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getCategorySchemaById } from '../../api/services/category/requests';
import { getPropertyById } from '../../api/services/property/requests';

import {
  addPriceDelimiters,
  formatPhoneNumber,
  generatePropertyDataStringMapped,
  getAddressString,
  getSubTree,
} from '../../common/utils/helpers';

import { EPriceType } from '../../api/services/property/types';

import useGlobalLoaderStore from '../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../common/stores/useGlobalErrorStore';
import useGlobalModalStore from '../../common/stores/useGlobalModalStore';

import usePropertyFieldsFromSchema from '../../common/hooks/usePropertyFieldsFromSchema';
import usePropertyCategories from '../../common/hooks/usePropertyCategories';

import Container from '../../common/components/Container/Container';
import DropdownSimple from '../../common/components/Dropdown/DropdownSimple';
import Loader from '../../common/components/Loader/Loader';

import PropertyPageCarousel from '../../features/property/PropertyPageCarousel';
import PropertyFieldWrapper from '../../features/property/PropertyFieldWrapper';
import PropertyFieldsColumn from '../../features/property/PropertyFieldsColumn';

import ComplaintModal from '../../features/property/components/modals/ComplaintModal';
import LinkModal from '../../features/search/components/modals/LinkModal';
import PropertyMap from '../../features/property/components/PropertyMap';

import NoImageIcon from '../../common/assets/icons/NoImageIcon';
import LocationIcon from '../../common/assets/icons/LocationIcon';
import LocationPin from '../../common/assets/icons/LocationPin';
import LinkIcon from '../../common/assets/icons/LinkIcon';

import ParametersIcon from '../../common/assets/icons/property-fields/ParametersIcon';
import PriceIcon from '../../common/assets/icons/property-fields/PriceIcon';
import ContactsIcon from '../../common/assets/icons/property-fields/ContactsIcon';
import DescriptionIcon from '../../common/assets/icons/property-fields/DescriptionIcon';
import FeatureIcon from '../../common/assets/icons/property-fields/FeatureIcon';

import { ReactComponent as ArrowBackIcon } from '../../common/assets/icons/arrowBackIcon.svg';
import { ReactComponent as AchtungIcon } from '../../common/assets/icons/achtungIcon.svg';

const propertyShowMoreLength = 200;

//todo: check translate after all done
const PropertyPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [descriptionShowMore, setDescriptionShowMore] = useState(false);

  const navigate = useNavigate();
  const { id, lang } = useParams();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { setChildren, setIsOpen } = useGlobalModalStore();

  const { categories } = usePropertyCategories();

  const { data } = useQuery(
    ['getEditPropertyById', 'getCategorySchemaById', id, lang],
    async () => {
      setLoader(true);
      const property = await getPropertyById(id as string);
      if (!property.category?.id) {
        setError('Unknown property category');
        setLoader(false);

        return null;
      }
      const categorySchema = await getCategorySchemaById(property.category.id);
      setLoader(false);
      return {
        property,
        categorySchema,
      };
    },
    {
      enabled: !!id,
      onError: (error: any) => {
        setError(error.response?.data?.message);
        setLoader(false);
        setIsLoading(false);
      },
      onSuccess: () => {
        setTimeout(() => setIsLoading(false), 0);
      },
    },
  );

  const property = data?.property;
  const categorySchema = data?.categorySchema;

  // const floor = property?.fields?.find(f => f.field.name === 'floor')?.value;
  // const floorsInTheBuild = property?.fields?.find(f => f.field.name === 'floorsInTheBuild')?.value;

  const titleString = useMemo(() => {
    if (!property) return '';

    const categoryNameArray = property.category?.name?.split('.') || [];
    const propertyType =
      categoryNameArray[0] === 'rent' ? t('rent') : t('sale');
    const totalPropertyArea = property.fields?.find(
      (f) => f.field.name === 'totalArea',
    )?.value;
    const roomsCount = property.fields?.find(
      (f) => f.field.name === 'roomsCountFlat',
    )?.value;
    const entities =
      getSubTree(categories, categoryNameArray.slice(0, -1))?.entities || [];
    const entityName = entities.find(
      (ent) => ent.id === property?.category?.id,
    )?.title;

    const parts = [
      t('for'),
      propertyType,
      roomsCount,
      entityName,
      totalPropertyArea ? `${totalPropertyArea} m²` : '',
    ];

    return parts.filter(Boolean).join(' ');
  }, [categories, property, t]);

  const pricePart = property?.price ? `, ${property.price} Kč` : '';
  const dataString =
    generatePropertyDataStringMapped(
      property?.category,
      property?.mappedFields,
      lang,
    ) + pricePart;

  const fancyBoxData = {
    property: dataString,
    phone: property?.contactPhone,
  };

  const propertyFields = usePropertyFieldsFromSchema(
    property?.fields,
    categorySchema?.groups?.find((g) => g.name === 'parameters'),
  );
  const priceAndRentalTermsFields = usePropertyFieldsFromSchema(
    property?.fields,
    categorySchema?.groups?.find((g) => g.name === 'priceAndRentalTerms'),
  );
  const featuresFields = usePropertyFieldsFromSchema(
    property?.fields,
    categorySchema?.groups?.find((g) => g.name === 'features'),
  );

  const contactFields = useMemo(() => {
    if (!property) return null;
    return [
      {
        label: t('email'),
        value: property.owner.email,
      },
      ...(property.contactPhone
        ? [
            {
              label: t('contact_phone_number'),
              value: formatPhoneNumber(property.contactPhone),
            },
          ]
        : []),
      ...(property.additionalPhone
        ? [
            {
              label: t('additional_phone_number'),
              value: formatPhoneNumber(property.additionalPhone),
            },
          ]
        : []),
    ];
  }, [property, t]);

  const propertyDescription = useMemo(() => {
    const description: string = property?.description as string;
    if (!description?.length) return null;

    if (propertyShowMoreLength > description.length) return description;
    return descriptionShowMore
      ? description
      : `${description.slice(0, propertyShowMoreLength)}...`;
  }, [property, descriptionShowMore]);

  const allImages = useMemo(() => {
    if (!property) return null;

    const mainImage = property.mainImage;
    const collateralImages = property.gallery?.map((img) => img.image) || [];

    return mainImage ? [...[mainImage], ...collateralImages] : collateralImages;
  }, [property]);

  const getFullLink = (lang: string, id: string) => {
    return `${window.location.protocol}//${window.location.host}/${lang}/property/${id}`;
  };

  const fullLink = getFullLink(lang as string, id as string);

  const handleBack = () => {
    navigate(-1);
  };

  const openComplaintModal = () => {
    if (!id) return;
    setChildren(<ComplaintModal id={id} />);
    setIsOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return (
      <div className="h-[100vh]">
        <Loader />
      </div>
    );
  }

  const { lat, lon } = property?.address?.coordinates || {};

  return (
    <div className="pt-[36px] pb-7 fade-in mb-[70px]">
      <Container>
        <div className="flex items-center justify-between mb-[38px]">
          <div className="flex items-center gap-[18px]">
            <button
              onClick={handleBack}
              className="flex-center w-[42px] h-[42px]"
            >
              <ArrowBackIcon />
            </button>
            <div className="text-[24px] text-[#11142D] font-bold">
              {titleString}
            </div>
          </div>
          <div className="flex items-center gap-4">
            <DropdownSimple
              title={
                <div className="flex-center w-10 h-10 rounded-[8px] bg-[#EAEFFE]">
                  <LinkIcon />
                </div>
              }
              menuClass="left-auto right-0"
            >
              <LinkModal link={fullLink} />
            </DropdownSimple>
            <button
              onClick={openComplaintModal}
              className="flex-center w-10 h-10 rounded-[8px] bg-[#EAEFFE]"
            >
              <AchtungIcon />
            </button>
          </div>
        </div>
        <div className="flex gap-[30px]">
          <div className="flex-1 w-0">
            <div>
              {allImages && allImages.length > 0 ? (
                <PropertyPageCarousel items={allImages} data={fancyBoxData} />
              ) : (
                <div className="flex-center aspect-[1.58] bg-[#F8F8F8] rounded-[10px]">
                  <NoImageIcon />
                </div>
              )}
            </div>
            <div className="flex justify-between items-center mt-[25px]">
              <div className="flex items-center gap-[6px]">
                <LocationIcon width={24} height={24} color="#006cfb" />
                <div className="text-[#333333]">
                  {getAddressString(property?.address)}
                </div>
              </div>
              <div>
                <div className="px-[9px] py-[7px] min-w-[120px] rounded-[4px] text-[#333333] text-[24px] font-semibold bg-[#006cfb0d] h-[44px] leading-[1.2] flex justify-center items-baseline">
                  {property?.price
                    ? `${addPriceDelimiters(property?.price)} Kč`
                    : '0 Kč'}
                  <span className="text-[16px]">
                    {property?.priceType === EPriceType.PerMonth
                      ? `/${t('mo')}`
                      : property?.priceType === EPriceType.PerDay
                        ? `/${t('day')}`
                        : ''}
                  </span>
                </div>
              </div>
            </div>
            {!!propertyFields?.length && (
              <div className="mt-[25px]">
                <PropertyFieldWrapper
                  label={t('parameters')}
                  icon={<ParametersIcon />}
                >
                  <div>
                    <PropertyFieldsColumn items={propertyFields} />
                  </div>
                </PropertyFieldWrapper>
              </div>
            )}
            {!!property?.description?.length && (
              <div className="relative mt-[25px]">
                <PropertyFieldWrapper
                  label={t('description')}
                  icon={<DescriptionIcon />}
                >
                  <div className="mt-[6px] text-[#666] leading-[1.6]">
                    {propertyDescription}

                    {(property?.description?.length as number) >
                      propertyShowMoreLength && (
                      <>
                        {' '}
                        <span
                          className="cursor-pointer text-blue"
                          onClick={() => setDescriptionShowMore((s) => !s)}
                        >
                          {descriptionShowMore
                            ? t('show_less')
                            : t('show_more')}
                        </span>
                      </>
                    )}
                  </div>
                </PropertyFieldWrapper>
              </div>
            )}
            {!!featuresFields?.length && (
              <div className="mt-[25px]">
                <PropertyFieldWrapper
                  label={t('features')}
                  icon={<FeatureIcon />}
                >
                  <div className="mt-[18px] line-clamp-3">
                    <PropertyFieldsColumn items={featuresFields} />
                  </div>
                </PropertyFieldWrapper>
              </div>
            )}
            {!!lat && !!lon && (
              <div className="mt-[25px]">
                <PropertyFieldWrapper
                  label={t('location')}
                  icon={<LocationPin />}
                >
                  <div className="w-full h-[318px] mt-[18px] rounded-[8px] overflow-hidden">
                    <PropertyMap lat={lat} lng={lon} />
                  </div>
                </PropertyFieldWrapper>
              </div>
            )}
          </div>
          <div className="w-[330px]">
            <div className="flex flex-col gap-[40px]">
              {!!priceAndRentalTermsFields?.length && (
                <PropertyFieldWrapper
                  label={t('price_and_rental_terms')}
                  icon={<PriceIcon />}
                >
                  <div className="mt-[18px]">
                    <PropertyFieldsColumn items={priceAndRentalTermsFields} />
                  </div>
                </PropertyFieldWrapper>
              )}
              <PropertyFieldWrapper
                label={t('contacts')}
                icon={<ContactsIcon />}
              >
                <div className="mt-[18px]">
                  <div className="flex items-center gap-[10px]">
                    {!property?.owner?.avatar ? (
                      <div className="flex-center rounded-full w-9 h-9 bg-[#006CFB] text-white">
                        {property?.owner?.name.charAt(0)}
                      </div>
                    ) : (
                      <img
                        className="rounded-full w-9 h-9"
                        src={property.owner.avatar.url}
                        alt="avatar"
                        width={40}
                        height={40}
                      />
                    )}
                    <div className="font-bold">{property?.owner.name}</div>
                  </div>
                  {!!contactFields?.length && (
                    <div className="mt-[20px]">
                      <PropertyFieldsColumn items={contactFields} />
                    </div>
                  )}
                </div>
              </PropertyFieldWrapper>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PropertyPage;
