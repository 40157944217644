import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { IMenuItem } from '../../constants/menu';

interface IMenuProps {
  items: IMenuItem[];
  className?: string;
}

const Menu: React.FC<IMenuProps> = ({ items, className = '' }) => {
  const { lang } = useParams<{ lang: string }>();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isActivePath = (href: string): boolean => {
    if (href === '') {
      return pathname === `/${lang}/` || pathname === `/${lang}`;
    }

    const regex = new RegExp(`^/${lang}${href}(?:/|$)`);
    return regex.test(pathname);
  };

  const menuItemsMap = items.map(({ label, href }) => {
    return (
      <Link key={label} to={`/${lang}${href}`}>
        <div
          className={`relative inline-block ${isActivePath(href) ? 'font-bold' : 'font-medium'} text-100 text-white border-b border-transparent hover:border-white before:content-[attr(title)] before:block before:text-100 before:font-bold before:text-transparent before:invisible before:h-0 before:overflow-hidden`}
          title={t(label)}
        >
          {t(label)}
        </div>
      </Link>
    );
  });

  return (
    <div className={className || `flex items-center gap-[20px] text-white`}>
      {menuItemsMap}
    </div>
  );
};

export default Menu;
