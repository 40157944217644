import React from 'react';

import useGlobalModalStore from '../../stores/useGlobalModalStore';

import FiltersModal from './modals/FiltersModal';

import { ReactComponent as FilterIcon } from '../../assets/icons/filterIcon.svg';

const PropertyFilters = ({ groups = [] }) => {
  const { setChildren, setIsOpen, setNoCross, onClose } = useGlobalModalStore();

  const openModal = () => {
    setChildren(<FiltersModal groups={groups} onClose={onClose} />);
    setNoCross(true);
    setIsOpen(true);
  };

  return (
    <div
      className={`relative inline-flex flex-center w-[40px] h-[40px] bg-[#EAEFFE] rounded-[5px]`}
    >
      <button
        onClick={openModal}
        className={`cursor-pointer flex-center w-[40px] h-[40px] bg-[#EAEFFE] rounded-[5px]`}
      >
        <div className="truncate">
          <FilterIcon />
        </div>
      </button>
    </div>
  );
};

export default PropertyFilters;

// const NumberField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <div>
//         <input
//           type="number"
//           value={filters[`FIELD_${field.name}From`] || ''}
//           onChange={(e) => handleChange(`FIELD_${field.name}From`, e.target.value)}
//           placeholder="From"
//         />
//         <input
//           type="number"
//           value={filters[`FIELD_${field.name}To`] || ''}
//           onChange={(e) => handleChange(`FIELD_${field.name}To`, e.target.value)}
//           placeholder="To"
//         />
//       </div>
//     </div>
//   );
// };

// Компонент для поля SELECT
// const SelectField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <select
//         value={filters[`FIELD_${field.name}`] || ''}
//         onChange={(e) => handleChange(`FIELD_${field.name}`, e.target.value)}
//       >
//         {field.options?.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// Компонент для поля TAG_SELECT
// const TagSelectField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <select
//         multiple
//         value={filters[`FIELD_${field.name}`] || []}
//         onChange={(e) => handleChange(`FIELD_${field.name}`, Array.from(e.target.selectedOptions, (option) => option.value))}
//       >
//         {field.options?.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// Компонент для поля TAG_MULTI_SELECT (множество тегов)
// const TagMultiSelectField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <select
//         multiple
//         value={filters[`FIELD_${field.name}`] || []}
//         onChange={(e) => handleChange(`FIELD_${field.name}`, Array.from(e.target.selectedOptions, (option) => option.value))}
//       >
//         {field.options?.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// Компонент для текстовых полей
// const StringField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <input
//         type="text"
//         value={filters[`FIELD_${field.name}`] || ''}
//         onChange={(e) => handleChange(`FIELD_${field.name}`, e.target.value)}
//       />
//     </div>
//   );
// };

// Компонент для поля DATE
// const DateField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <input
//         type="date"
//         value={filters[`FIELD_${field.name}`] || ''}
//         onChange={(e) => handleChange(`FIELD_${field.name}`, e.target.value)}
//       />
//     </div>
//   );
// };

// const RadioField = ({ field, handleChange, filters }) => {
//   return (
//     <div>
//       <label>{field.label}</label>
//       <div>
//         {field.options?.map((option) => (
//           <label key={option.value}>
//             <input
//               type="radio"
//               name={`FIELD_${field.name}`}
//               value={option.value}
//               checked={filters[`FIELD_${field.name}`] === option.value}
//               onChange={() => handleChange(`FIELD_${field.name}`, option.value)}
//             />
//             {option.label}
//           </label>
//         ))}
//       </div>
//     </div>
//   );
// };
