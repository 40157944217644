import React from 'react';

import PropertyMapList from './PropertyMapList';

import ChevronIcon from '../../../common/assets/icons/ChevronIcon';

interface IPropertyPanelProps {
  showPanel: boolean;
  togglePanel: () => void;
}

const PropertyPanel: React.FC<IPropertyPanelProps> = ({
  showPanel,
  togglePanel,
}) => (
  <div
    className={`relative h-[calc(100vh-175px)] flex-center bg-[#fff] py-[45px] z-[5] fade-in transition-width duration-500 ${showPanel ? 'w-[606px]' : 'w-[66px]'}`}
  >
    <div
      className={`${showPanel ? 'opacity-100 duration-500' : 'opacity-0 duration-200'} transition-opacity`}
    >
      <PropertyMapList />
    </div>
    <div
      onClick={togglePanel}
      className="absolute top-1/2 right-0 z-[50] transform translate-x-1/2 -translate-y-1/2 flex-center w-[60px] h-[60px] bg-[#fff] rounded-full cursor-pointer"
    >
      <ChevronIcon
        className={`transform ${showPanel ? '' : 'rotate-180 translate-x-[5px]'}`}
        width={24}
        height={24}
        color="#006CFB"
      />
    </div>
  </div>
);

export default PropertyPanel;
