import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { getCategoryList } from '../../api/services/category/requests';

import { ICategory } from '../types/dto/category';

import useGlobalLoaderStore from '../stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../stores/useGlobalErrorStore';

export interface IConvertedCategory {
  id: string;
  title: string;
  name: string;
  fullName?: string;
  description?: string;
  entities?: IConvertedCategory[];
  children?: IConvertedCategory[];
}

const convertCategoryTree = (nodes: ICategory[]): IConvertedCategory[] => {
  return nodes.map((node) => {
    const childrenWithChildren: IConvertedCategory[] = [];
    const entities: IConvertedCategory[] = [];

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        if (!child.children || child.children.length === 0) {
          entities.push({
            id: child.id,
            title: child.title,
            name: child.name,
          });
        } else {
          childrenWithChildren.push(convertCategoryTree([child])[0]);
        }
      });
    }

    const nameStringArr = node.name.split('.');
    const result: IConvertedCategory = {
      id: node.id,
      title: node.title,
      fullName: node.name,
      name: nameStringArr[nameStringArr.length - 1],
      description: node.description,
    };

    if (entities.length > 0) {
      result.entities = entities;
    }

    if (childrenWithChildren.length > 0) {
      result.children = childrenWithChildren;
    }

    return result;
  });
};
//
// function extractLeafNodes(nodes) {
//   const result = [];
//
//   function traverse(node) {
//     if (node.children && node.children.length > 0) {
//       for (const child of node.children) {
//         traverse(child);
//       }
//     } else {
//       result.push({
//         name: node.name,
//         title: node.title,
//         id: node.id,
//       });
//     }
//   }
//
//   for (const node of nodes) {
//     traverse(node);
//   }
//
//   return result;
// }

const usePropertyCategories = () => {
  const { lang } = useParams();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { data: categories = [] } = useQuery(
    ['categories', lang],
    async () => {
      setLoader(true);

      const data = await getCategoryList();

      setLoader(false);
      return data;
    },
    {
      onError: (error: any) => {
        setError(error.response?.data?.message);
        setLoader(false);
      },
    },
  );

  return {
    categories: convertCategoryTree(categories),
  };
};

export default usePropertyCategories;
