import { AxiosResponse } from 'axios';

import axiosInstance from '../../axios';

import { IResponse } from '../../../common/types/response';
import { IPostLeadPayload } from './types';

export const postLead = async (payload: IPostLeadPayload) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.post(
    `/lead`,
    payload,
  );
  return response.data.data;
};
