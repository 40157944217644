import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import usePropertySearchStore from '../../../common/stores/usePropertySearchStore';

import PropertySearchBar from '../../../common/components/PropertySearchBar/PropertySearchBar';

import MapIcon from '../../../common/assets/icons/MapIcon';

const PropertySearchMain = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const { t } = useTranslation();

  const { propertyTypes } = usePropertySearchStore();

  const handleMapRedirect = () => {
    navigate(`/${lang}/map`);
  };

  const handleSearchRedirect = () => {
    const type = propertyTypes.path[0] === 'rent' ? 'rent' : 'buy';
    navigate(`/${lang}/search/${type}`);
  };

  return (
    <div className="flex items-center gap-5 fade-in">
      <div className="flex-1">
        <PropertySearchBar className="border-white" />
      </div>
      <div>
        <button
          onClick={handleMapRedirect}
          className="flex-center w-[50px] h-[48px] bg-white rounded-[8px]"
        >
          <MapIcon />
        </button>
      </div>
      <div>
        <button
          onClick={handleSearchRedirect}
          className="flex-center w-[188px] h-[48px] bg-white rounded-[8px] text-[#006CFB] font-semibold"
        >
          {t('button-search')}
        </button>
      </div>
    </div>
  );
};

export default PropertySearchMain;
