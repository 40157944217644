import { useTranslation } from 'react-i18next';

import Container from '../../../common/components/Container/Container';

import PropertySearchMain from './PropertySearchMain';

const PropertySearchSection = () => {
  const { t } = useTranslation();

  return (
    <div className="py-[56px] bg-[#006CFB]">
      <Container>
        <h1 className="flex flex-col gap-1 text-[38px] text-white font-bold mb-[46px] fade-in">
          <span className="leading-[50px]">{t('main-header-1-1')}</span>
          <span className="leading-[50px]">{t('main-header-1-2')}</span>
        </h1>
        <div className="relative z-[999]">
          <PropertySearchMain />
        </div>
      </Container>
    </div>
  );
};

export default PropertySearchSection;
