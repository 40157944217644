import { create } from 'zustand';

import { IGetPropertySearchFullResponse } from '../../api/services/property/types';
import { IPropertySearch } from '../types/dto/property';

interface IUsePropertyStore {
  properties: IPropertySearch[];
  polygon: any;
  total: number;
  limit: number;
  page: number;
  refetch: (() => void) | null;
  setProperties: (data: IGetPropertySearchFullResponse<any>) => void;
  setLimit: (limit: number) => void;
  setPage: (page: number) => void;
  setRefetch: (refetch: (() => void) | null) => void;
}

const usePropertyStore = create<IUsePropertyStore>((set) => ({
  properties: [],
  polygon: {},
  total: 0,
  limit: 40,
  page: 1,
  refetch: null,
  setProperties: (data) =>
    set({ properties: data.items, total: data.total, polygon: data.polygon }),
  setLimit: (limit) => set({ limit }),
  setPage: (page) => set({ page }),
  setRefetch: (refetch) => set({ refetch }),
}));

export default usePropertyStore;
