import { useMemo, FC } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  addPriceDelimiters,
  generatePropertyDataString,
  getAddressString,
} from '../../../common/utils/helpers';

import { IPropertySearch } from '../../../common/types/dto/property';

import PropertyCarousel from '../../../common/components/PropertyCarousel/PropertyCarousel';

import NoImageIcon from '../../../common/assets/icons/NoImageIcon';

interface IPropertyMapItemProps {
  item: IPropertySearch;
}

//todo: check translate after all done
const PropertyMapItem: FC<IPropertyMapItemProps> = ({
  item: { id, mainImage, gallery = [], price, address, fields, category },
}) => {
  const { lang } = useParams();

  const addressString = getAddressString(address);

  const allImages = useMemo(() => {
    const collateralImages = gallery;

    return mainImage ? [...[mainImage], ...collateralImages] : collateralImages;
  }, [gallery, mainImage]);

  const meterPrice = Math.round(price / 32.2);

  const dataString = generatePropertyDataString(category, fields);

  return (
    <Link
      className="rounded-[4px] hover:bg-[#EAEFFE59] cursor-pointer fade-in"
      to={`/${lang}/property/${id}`}
    >
      <div className="w-full mb-[15px]">
        {allImages && allImages.length > 0 ? (
          <PropertyCarousel items={allImages} />
        ) : (
          <div className="flex-center aspect-[1.52] bg-[#F8F8F8] rounded-[4px]">
            <NoImageIcon />
          </div>
        )}
      </div>
      <div>
        <div className="mb-[15px]">
          <div className="text-[22px] text-[#006CFB] leading-[1] mb-1">
            {dataString}
          </div>
          <div className="text-[14px] text-[#8C8C8C] leading-[1]">
            {addressString}
          </div>
        </div>
        <div>
          <div className="text-[22px] text-[#11142D] leading-[1] mb-1">
            {addPriceDelimiters(price)} Kč
          </div>
          <div className="text-[14px] text-[#8C8C8C] leading-[1]">
            {addPriceDelimiters(meterPrice)} Kč/m2
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PropertyMapItem;
