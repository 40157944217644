import { useTranslation } from 'react-i18next';

import usePropertyStore from '../../../common/stores/usePropertyStore';

import { ReactComponent as EmptyListImage } from '../../../common/assets/images/empty-search.svg';

import PropertySort from '../../../common/components/PropertySort/PropertySort';

import PropertyMapItem from './PropertyMapItem';

const PropertyMapList = () => {
  const { t } = useTranslation();

  const { properties, total } = usePropertyStore();

  const propertiesMap = properties.map((item) => (
    <PropertyMapItem key={item.id} item={item} />
  ));

  return (
    <div className="h-[calc(100vh-175px)] w-[534px] px-4 pb-4 overflow-auto scrollbar-none">
      <div>
        <div className="flex items-center gap-4 mb-[25px]">
          <div className="text-[14px] text-[#000]">
            {total} {t('listings_found')}
          </div>
          <PropertySort />
        </div>
        {propertiesMap.length === 0 && (
          <div className={'flex flex-col items-center'}>
            <EmptyListImage height={350} />
            <div className="text-[18px] text-[#605E76] leading-[1.14] mb-[35px] fade-in">
              {t('empty_list')}
            </div>
          </div>
        )}
        <div className="flex flex-col gap-[25px] w-[492px]">
          {propertiesMap}
        </div>
      </div>
    </div>
  );
};

export default PropertyMapList;
