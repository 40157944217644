import React from 'react';

import Container from '../../common/components/Container/Container';

interface IAgenciesFeaturesBlockProps {
  title: string;
  children: React.ReactNode;
}

const AgenciesSection: React.FC<IAgenciesFeaturesBlockProps> = ({
  title,
  children,
}) => {
  return (
    <div className="py-[50px]">
      <Container>
        <div className="w-full flex flex-col items-center gap-[25px]">
          <h2 className="text-[32px] leading-[44px] lg:text-[55px] text-heading lg:leading-[75px] font-bold">
            {title}
          </h2>
          <div className="w-full">{children}</div>
        </div>
      </Container>
    </div>
  );
};

export default AgenciesSection;
