import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AgenciesHeroSection from '../../features/agencies/AgenciesHeroSection';
import AgenciesSection from '../../features/agencies/AgenciesSection';
import AgenciesTabs from '../../features/agencies/AgenciesTabs';
import AgenciesDiagram from '../../features/agencies/AgenciesDiagram';
import AgenciesFAQ from '../../features/agencies/AgenciesFAQ';

const AgenciesPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <AgenciesHeroSection />
      <AgenciesSection title={t('why_choose_reelty')}>
        <AgenciesTabs />
      </AgenciesSection>
      <AgenciesSection title={t('how_attract_clients')}>
        <AgenciesDiagram />
      </AgenciesSection>
      <AgenciesSection title={t('frequently_asked_questions')}>
        <AgenciesFAQ />
      </AgenciesSection>
    </div>
  );
};

export default AgenciesPage;
