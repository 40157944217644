import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { useJsApiLoader, LoadScriptProps } from '@react-google-maps/api';

import { GOOGLE_MAP_KEY } from '../../common/constants/env';

interface IGoogleMapsContextProps {
  isApiLoaded: boolean;
  loadError: Error | undefined;
}

const GoogleMapsContext = createContext<IGoogleMapsContextProps | null>(null);

const libraries: LoadScriptProps['libraries'] = [
  'places',
  'geometry',
  'marker',
];

interface IGoogleMapsProviderProps {
  children: ReactNode;
}

export const GoogleMapsProvider: React.FC<IGoogleMapsProviderProps> = ({
  children,
}) => {
  const [isApiLoaded, setIsApiLoaded] = useState(false);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_KEY || '',
    libraries,
  });

  useEffect(() => {
    if (isLoaded) {
      setIsApiLoaded(true);
    }
  }, [isLoaded]);

  return (
    <GoogleMapsContext.Provider value={{ isApiLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = (): IGoogleMapsContextProps | null =>
  useContext(GoogleMapsContext);
