import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const MapIcon: FC<ISvgProps> = ({
  height = 26,
  width = 25,
  color = '#CCCCCC',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 23.7222C0 23.3833 0.114935 23.0583 0.31952 22.8187C0.524105 22.5791 0.801582 22.4444 1.09091 22.4444H22.9091C23.1984 22.4444 23.4759 22.5791 23.6805 22.8187C23.8851 23.0583 24 23.3833 24 23.7222C24 24.0611 23.8851 24.3861 23.6805 24.6257C23.4759 24.8654 23.1984 25 22.9091 25H1.09091C0.801582 25 0.524105 24.8654 0.31952 24.6257C0.114935 24.3861 0 24.0611 0 23.7222ZM0 13.5C0 13.1611 0.114935 12.8361 0.31952 12.5965C0.524105 12.3568 0.801582 12.2222 1.09091 12.2222H22.9091C23.1984 12.2222 23.4759 12.3568 23.6805 12.5965C23.8851 12.8361 24 13.1611 24 13.5C24 13.8389 23.8851 14.1639 23.6805 14.4035C23.4759 14.6432 23.1984 14.7778 22.9091 14.7778H1.09091C0.801582 14.7778 0.524105 14.6432 0.31952 14.4035C0.114935 14.1639 0 13.8389 0 13.5ZM0 3.27778C0 2.93889 0.114935 2.61388 0.31952 2.37425C0.524105 2.13462 0.801582 2 1.09091 2H22.9091C23.1984 2 23.4759 2.13462 23.6805 2.37425C23.8851 2.61388 24 2.93889 24 3.27778C24 3.61667 23.8851 3.94167 23.6805 4.1813C23.4759 4.42093 23.1984 4.55556 22.9091 4.55556H1.09091C0.801582 4.55556 0.524105 4.42093 0.31952 4.1813C0.114935 3.94167 0 3.61667 0 3.27778Z"
        fill={color}
      />
    </svg>
  );
};

export default MapIcon;
