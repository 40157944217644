import { FC } from 'react';

import { ISvgProps } from '../../types/media';

const CrossIcon: FC<ISvgProps> = ({
  height = 24,
  width = 24,
  color = '#666',
  strokeWidth = '1',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="1"
        x2="17"
        y2="17"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <line
        x1="1"
        y1="17"
        x2="17"
        y2="1"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default CrossIcon;
