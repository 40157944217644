import { AxiosResponse } from 'axios';

import axiosInstance from '../../axios';

import { IResponse } from '../../../common/types/response';
import { ICategory, ICategorySchema } from '../../../common/types/dto/category';

export const getCategoryList = async () => {
  const response: AxiosResponse<IResponse<ICategory[]>> =
    await axiosInstance.get('/category/list');
  return response.data.data;
};

export const getCategorySchemaById = async (categoryId: string) => {
  const response: AxiosResponse<IResponse<ICategorySchema>> =
    await axiosInstance.get(`/category/${categoryId}/schema`);
  return response.data.data;
};
