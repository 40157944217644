import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ReactNode,
} from 'react';

interface IDropdownProps {
  children: ReactNode;
  title?: ReactNode;
  className?: string;
  buttonClass?: string;
  menuClass?: string;
  maxHeight?: number;
}

export interface IDropdownHandle {
  closeDropdown: () => void;
  openDropdown: () => void;
  toggleDropdown: () => void;
}

const Dropdown = forwardRef<IDropdownHandle, IDropdownProps>(
  (
    {
      children,
      title = '',
      className = '',
      buttonClass = '',
      menuClass = '',
      maxHeight = 300,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(ref, () => ({
      closeDropdown: () => setIsOpen(false),
      openDropdown: () => setIsOpen(true),
      toggleDropdown: () => setIsOpen((prev) => !prev),
    }));

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <div ref={dropdownRef} className={`relative inline-flex ${className}`}>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          className={`w-full h-full cursor-pointer ${buttonClass}`}
        >
          <div className="truncate">{title}</div>
        </button>
        <div
          className={`absolute top-full left-0 z-[1000] ${
            isOpen ? '' : 'hidden'
          } min-w-full bg-white p-0 mt-[15px] rounded-[8px] border border-[#E2E8F0] overflow-hidden fade-in animation-fast ${menuClass}`}
        >
          <div className="overflow-auto" style={{ maxHeight }}>
            {children}
          </div>
        </div>
      </div>
    );
  },
);

Dropdown.displayName = 'Dropdown';

export default Dropdown;
