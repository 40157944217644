import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ExtLinkIcon from '../../common/assets/icons/ExtLinkIcon';

const DocumentsPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex-center flex-col items-center h-full pb-[70px]">
      <div className="flex flex-col items-center pt-[35px]">
        <h2 className="text-2xl text-[#333333] font-bold">
          {t('documents_and_files')}
        </h2>
      </div>
      <div className="grid gap-3 text-[#26203B] mt-[35px]">
        <div className="flex-center gap-1 border-b border-[#00000026]">
          <a
            href="/Complaints_procedure.pdf"
            download="Complaints_procedure.pdf"
          >
            {t('complaints_procedure')}
          </a>
          <a
            href="/Complaints_procedure.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ExtLinkIcon />
          </a>
        </div>
        <div className="flex-center gap-1 border-b border-[#00000026]">
          <a href="/GTC_broker.pdf" download="GTC_broker.pdf">
            {t('gtc_broker')}
          </a>
          <a href="/GTC_broker.pdf" target="_blank" rel="noopener noreferrer">
            <ExtLinkIcon />
          </a>
        </div>
        <div className="flex-center gap-1 border-b border-[#00000026]">
          <a href="/GTC_users.pdf" download="GTC_users.pdf">
            {t('gtc_users')}
          </a>
          <a href="/GTC_users.pdf" target="_blank" rel="noopener noreferrer">
            <ExtLinkIcon />
          </a>
        </div>
        <div className="flex-center gap-1 border-b border-[#00000026]">
          <a href="/personal_data.pdf" download="personal_data.pdf">
            {t('personal_data')}
          </a>
          <a
            href="/personal_data.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ExtLinkIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DocumentsPage;
