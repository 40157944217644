import { useState, useEffect, useRef, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import { addPriceDelimiters } from '../../utils/helpers';

import DropdownSimple from '../Dropdown/DropdownSimple';

interface IRangeValue {
  from: string;
  to: string;
}

interface ITitleComponentProps {
  title?: string;
}

interface IPropertyRangeSelectorProps {
  placeholder?: string;
  unit?: string;
  defaultValue: IRangeValue;
  onChange: (value: IRangeValue) => void;
}

const isCorrectValue = (value: IRangeValue) => {
  const { from, to } = value;

  if (!from || !to) return true;

  return +from <= +to;
};

const TitleComponent: FC<ITitleComponentProps> = ({ title = '' }) => {
  return (
    <div className="flex-center h-[40px] px-[15px] bg-[#fff] border border-[#006CFB] rounded-[8px] text-[12px] text-[#11142D] font-semibold">
      {title}
    </div>
  );
};

const PropertyRangeSelector: FC<IPropertyRangeSelectorProps> = ({
  placeholder = '',
  unit = '',
  defaultValue,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);

  const { t } = useTranslation();

  const dropdownRef = useRef<any>(null);

  const valueToString = () => {
    if (!defaultValue.to && !defaultValue.from) {
      return placeholder;
    }

    if (!isCorrectValue(defaultValue)) {
      return t('incorrect_interval');
    }

    return `${!defaultValue.to?.length ? t('placeholder-from') + ': ' : ''}${defaultValue.from?.length ? addPriceDelimiters(defaultValue.from) + ' ' + unit : t('placeholder-to') + ': '}${defaultValue.to?.length ? ' - ' + addPriceDelimiters(defaultValue.to) + ' ' + unit : ''}`;
  };

  const handleFromChange = (values: any) => {
    const { value } = values;

    setValue((prevValue: IRangeValue) => ({
      ...prevValue,
      from: value,
    }));
  };

  const handleToChange = (values: any) => {
    const { value } = values;

    setValue((prevValue: IRangeValue) => ({
      ...prevValue,
      to: value,
    }));
  };

  const handleSubmit = () => {
    if (isCorrectValue(value)) {
      onChange(value);
    } else {
      setValue(defaultValue);
    }

    dropdownRef.current?.closeDropdown();
  };

  useEffect(() => {
    if (value.to !== defaultValue.to || value.from !== defaultValue.from) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line
  }, [defaultValue]);

  return (
    <DropdownSimple
      ref={dropdownRef}
      menuClass="border-0 shadow"
      title={<TitleComponent title={valueToString()} />}
    >
      <div className="flex flex-col gap-[15px] w-[275px] py-4 px-5">
        <div className="flex items-center gap-[15px]">
          <div className="relative">
            <NumericFormat
              thousandSeparator=" "
              onValueChange={handleFromChange}
              value={value.from || ''}
              step={1}
              className="w-full h-[40px] border border-[#E2E8F0] rounded-[5px] outline-none pl-[6px] pr-[28px] text-[12px] text-[#666666] placeholder-[#AAAAAA80]"
              placeholder={t('placeholder-from')}
              allowNegative={false}
            />
            <div className="absolute top-1/2 right-[6px] transform -translate-y-1/2 text-[12px] text-[#333333]">
              {unit}
            </div>
          </div>
          <div className="relative">
            <NumericFormat
              thousandSeparator=" "
              onValueChange={handleToChange}
              value={value.to || ''}
              step={1}
              className="w-full h-[40px] border border-[#E2E8F0] rounded-[5px] outline-none pl-[6px] pr-[28px] text-[12px] text-[#666666] placeholder-[#AAAAAA80]"
              placeholder={t('placeholder-to')}
              allowNegative={false}
            />
            <div className="absolute top-1/2 right-[6px] transform -translate-y-1/2 text-[12px] text-[#333333]">
              {unit}
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={handleSubmit}
            className="flex-center w-full h-[24px] bg-[#006CFB] rounded-[5px] text-[12px] text-[#ffffff]"
          >
            {t('apply')}
          </button>
        </div>
      </div>
    </DropdownSimple>
  );
};

export default PropertyRangeSelector;
