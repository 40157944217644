import { useRef, FC } from 'react';

import DropdownSimple from '../Dropdown/DropdownSimple';

import CheckIcon from '../../assets/icons/CheckIcon';

interface IOption {
  id: string;
  title: string;
  active: boolean;
  onChange?: () => void;
}

interface ITitleComponentProps {
  title?: string;
}

interface IPropertyTypeSelectorItemProps {
  title?: string;
  options?: IOption[];
  isEntities?: boolean;
}

const TitleComponent: FC<ITitleComponentProps> = ({ title = '' }) => {
  return (
    <div className="flex-center h-[40px] px-[15px] bg-[#fff] border border-[#006CFB] rounded-[8px] text-[12px] text-[#11142D] font-semibold">
      {title}
    </div>
  );
};

const PropertyTypeSelectorItem: FC<IPropertyTypeSelectorItemProps> = ({
  title = '',
  options = [],
  isEntities = false,
}) => {
  const dropdownRef = useRef<any>(null);

  const optionsMap = options.map((option) => {
    const { id, title, active, onChange } = option;
    const handleOnChange = () => {
      !!onChange && onChange();
      !isEntities && dropdownRef.current?.closeDropdown();
    };
    const icon = isEntities ? (
      <div className="relative flex-center w-4 h-4">
        <input
          type="checkbox"
          className="appearance-none w-4 h-4 border border-[#006CFB] rounded-[2px] bg-transparent shrink-0 cursor-pointer checked:rounded-[4px] checked:bg-[#006CFB]"
          checked={active}
          readOnly
        />
        <CheckIcon
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
          height={10}
          width={10}
          color="white"
          strokeWidth={2.5}
        />
      </div>
    ) : (
      active && <CheckIcon height={10} width={10} strokeWidth={2.5} />
    );

    return (
      <div
        key={id}
        onClick={handleOnChange}
        className="flex items-center justify-between gap-[15px] w-full cursor-pointer"
      >
        <div
          className={`text-[12px] whitespace-nowrap ${active && !isEntities ? 'text-[#006CFB]' : 'text-[#5D5D5D]'}`}
        >
          {title}
        </div>
        <div className="w-[10px] h-[10px]">{icon}</div>
      </div>
    );
  });
  return (
    <DropdownSimple
      ref={dropdownRef}
      menuClass="border-0 shadow"
      title={<TitleComponent title={title} />}
    >
      <div className="flex flex-col gap-[15px] py-2.5 px-5">{optionsMap}</div>
    </DropdownSimple>
  );
};

export default PropertyTypeSelectorItem;
