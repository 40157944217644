export const allowedLanguages = ['cz', 'en']; // , "ru"];

export const allowedLanguagesWithLabels = [
  {
    language: 'cz',
    label: 'czech',
  },
  {
    language: 'en',
    label: 'english',
  },
  // {
  //   language: "ru",
  //   label: "russian",
  // },
];
