import React, { ReactNode } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';

import i18n from './i18n';

interface I18nProviderProps {
  children: ReactNode;
}

const I18nProvider: React.FC<I18nProviderProps> = ({ children }) => (
  <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
);

export default I18nProvider;

export const useChangeLanguage = (): ((language: string) => void) => {
  const { i18n } = useTranslation();
  const { lang } = useParams<{ lang: string }>(); // Define the expected params type
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (language: string) => {
    const regex = new RegExp(`/${lang}(?=/|$)`, 'i');
    const newLink = pathname.replace(regex, `/${language}`);
    i18n.changeLanguage(language).then(() => {
      navigate(newLink);
    });
  };
};
