import axios, { InternalAxiosRequestConfig, AxiosError } from 'axios';

import { API_URL } from '../common/constants/env';

interface IErrorResponse {
  message: string;
}

const getLocaleFromUrl = (): string => {
  const path = window.location.pathname;
  const match = path.match(/^\/([a-z]{2})(?:\/|$)/);
  return match ? match[1] : 'cz';
};

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    post: {
      'Content-Type': 'application/json',
    },
  },
});

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    config.headers = config.headers || {};
    config.headers['locale'] = getLocaleFromUrl();
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError<IErrorResponse>) => {
    if (error.response?.data?.message === 'Unauthorized') {
      if (window.location.pathname !== '/cz/') {
        window.location.href = '/cz/';
      }
    }
    throw error;
  },
);

export default axiosInstance;
